import React, { useContext, useEffect, useState } from 'react';
import ElementInspector from './shared/ElementInspector';
import './InputElementInspector/styles.scss';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import WKPInputSubInspector from './InputElementInspector/WKPInputSubInspector';
import WKPImportSubInspector from './InputElementInspector/WKPImportSubInspector';
import FAInputSubInspector from './InputElementInspector/FAInputSubInspector';
import InputOptionsSubInspector from './InputElementInspector/InputOptionsSubInspector';
import SFTPInputSubInspector from './InputElementInspector/SFTPInputSubInspector';
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { DataFlowEditorContext } from './DataFlowEditorContext';
import { useInputSourceFiles } from './InputElementInspector/useInputSourceFiles';
import { syncElementStateReducer } from './useDataFlowStateReducer/syncElementStateReducer';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import {
  FIND_AND_REPLACE_PROMPT,
  WKP_INPUT_FILE_IMPORT,
  WKP_INPUT_SINGLE_FILE_IMPORT,
} from '../../../constants/featureFlags';
import {
  FILE_COMPLETED_STATUS,
  FILE_FAILED_STATUS,
  FILE_IMPORT_RUNNING_STATUS,
  FILE_UPLOAD_RUNNING_STATUS,
  FILE_IMPORT_CANCELLED_STATUS,
} from './FileSettings/FilePropertiesStatusTypes';
import { STEP_CANCEL, STEP_COMPLETE, STEP_IMPORT_FILE_COMPLETED } from './InputElementInspector/useImportFile';

const InputElementInspector = ({ elementId, elementData, elementType, updateData, isDirty }) => {
  const { dataFlowState, dataFlowActions, fileImport, sourceFileUpload } = useContext(DataFlowEditorContext);
  const dataflowId = dataFlowState.id;
  const inputSingleFileImportFlag = isFeatureFlagEnabled(WKP_INPUT_SINGLE_FILE_IMPORT);
  const inputFileImportFlag = isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT);

  const { setFAInputProperties } = dataFlowActions;

  const { sourceFiles, setSourceFiles } = useInputSourceFiles(
    dataFlowState.id,
    elementId,
    elementData?.pendingSourceFileVersionId,
    false
  );
  const { importFileState } = fileImport;
  const { uploadedFilesByInput } = sourceFileUpload;
  const { path } = useRouteMatch();
  const history = useHistory();
  const [alerts, setAlerts] = useState([]);
  const updateAlerts = errors => {
    setAlerts(errors);
  };
  const [uploadingElementId, setUploadingElementId] = useState(null);

  useEffect(() => {
    const postFixPath = elementData?.integrationType;
    const fields = elementData?.fields;
    const importElementData = importFileState && importFileState[elementId];

    const sourceFile = sourceFiles ? sourceFiles[0] : null;

    // If there is no source file or we got an error, we have to cleanup the state
    if (sourceFile?.error) {
      setSourceFiles([]);
      updateData({
        fields: undefined,
        pendingSourceFileVersionId: undefined,
        import: undefined,
        ...(inputFileImportFlag && {
          uploadedFile: undefined,
          integrationType: '',
        }),
      });
      return;
    }

    // update logic when restore dataflow if source file already existed
    if (fields && fields.length > 0 && (postFixPath === undefined || postFixPath === '')) {
      let fields = {};
      if (sourceFile) {
        if (sourceFile.id && sourceFile.activeVersion) {
          fields = sourceFile.activeVersion.fields ? JSON.parse(sourceFile.activeVersion.fields) : null;
        } else {
          fields = sourceFile.fields ? JSON.parse(sourceFile.fields) : null;
        }

        const intgType =
          fields ||
          elementData?.integrationType === 'FA' ||
          importElementData?.payload?.elementData?.integrationType === 'FA'
            ? 'FA'
            : elementData?.import?.status === FILE_COMPLETED_STATUS
              ? 'WKP-IMPORT'
              : 'WKP';

        if (fields) {
          setFAInputProperties({
            workflow: { value: fields.workFlowName },
            showDataType: true,
            focusPeriod: fields.focusPeriod,
          });
        }
        if (sourceFile.error) {
          updateData({ appendDataSource: undefined });
        }

        updateData({
          integrationType: intgType,
        });
        history.replace(`/data-flows/${dataflowId}/editor/${intgType}`);
        return;
      } else {
        updateData({ appendDataSource: undefined });
      }
    }

    const redirectToWkpImport =
      isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) &&
      !postFixPath &&
      !!elementData?.uploadedFile &&
      !(elementData?.import?.status === FILE_IMPORT_CANCELLED_STATUS);

    if (postFixPath) {
      history.replace(`/data-flows/${dataflowId}/editor/${postFixPath}`);
    } else if (redirectToWkpImport) {
      history.replace(`/data-flows/${dataflowId}/editor/WKP-IMPORT`);
    } else {
      history.replace(`/data-flows/${dataflowId}/editor`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataflowId, sourceFiles]);

  useEffect(() => {
    if (isFeatureFlagEnabled(FIND_AND_REPLACE_PROMPT)) {
      // will trigger a sync only on first render after find and replace is ran
      if (dataFlowState?.findAndReplaceDialog?.triggerReRun) {
        // if will sync element state with updated configs for each element that was affected by find and replace
        let elements = dataFlowState.elements;
        let elementsToReplace = Object.keys(dataFlowState?.findAndReplaceDialog?.mapping);
        elementsToReplace.forEach(elementDataId => {
          if (elements[elementDataId]) {
            let elementMappingKey = Object.keys(dataFlowState?.findAndReplaceDialog.mapping[elementDataId]);
            elementMappingKey.forEach(key => {
              elements[elementDataId]['elementData'][key] =
                dataFlowState?.findAndReplaceDialog.mapping[elementDataId][key];
            });
          }
        });
        const newDataFlowState = { ...dataFlowState, elements: elements };
        // will update FindAndReplacePropDialog.triggerReRun to false after first render
        dataFlowActions.setFindAndReplacePropDialog({ ...dataFlowState.findAndReplaceDialog, triggerReRun: false });
        syncElementStateReducer(newDataFlowState, 'sync-element-state');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFlowState?.findAndReplaceDialog?.triggerReRun]);

  useEffect(() => {
    if (!inputSingleFileImportFlag || !inputFileImportFlag) return;

    const uploadingElement = Object.entries(dataFlowState.elements).find(([key, value]) => {
      const { elementData } = value;
      const importStatus = elementData?.import?.status;
      const uploadStatus = elementData?.uploadedFile?.status;

      if (!importStatus && !uploadStatus) return false;

      return importStatus === FILE_IMPORT_RUNNING_STATUS || uploadStatus === FILE_UPLOAD_RUNNING_STATUS;
    });

    if (uploadingElement) {
      const id = uploadingElement[0];
      const element = uploadingElement[1];
      if (
        (element?.elementData?.import === undefined || element?.elementData?.import?.status === FILE_FAILED_STATUS) &&
        uploadedFilesByInput &&
        uploadedFilesByInput[id]?.status !== FILE_UPLOAD_RUNNING_STATUS
      ) {
        setUploadingElementId(null);
        return;
      }

      if (
        (element?.elementData?.import !== undefined || element?.elementData?.import?.status === FILE_FAILED_STATUS) &&
        (importFileState[id]?.currentStep === STEP_CANCEL ||
          importFileState[id]?.currentStep === STEP_COMPLETE ||
          importFileState[id]?.currentStep === STEP_IMPORT_FILE_COMPLETED)
      ) {
        setUploadingElementId(null);
        return;
      }

      setUploadingElementId(uploadingElement[0]);
    } else {
      setUploadingElementId(null);
    }
  }, [dataFlowState.elements, inputSingleFileImportFlag, inputFileImportFlag, importFileState, uploadedFilesByInput]);

  return (
    <ElementInspector {...{ alerts, elementData, elementId, elementType, updateData, isDirty }}>
      <Switch>
        <Route exact path={path}>
          <InputOptionsSubInspector {...{ updateData, uploadingElementId }} />
        </Route>
        <Route exact path={`${path}/WKP`}>
          <WKPInputSubInspector
            {...{ elementData, elementId, elementType, updateData, isDirty }}
            updateAlerts={updateAlerts}
          />
        </Route>
        <Route exact path={`${path}/WKP-IMPORT`}>
          <WKPImportSubInspector
            {...{ elementData, elementId, elementType, updateData, isDirty, uploadingElementId }}
            updateAlerts={updateAlerts}
          />
        </Route>
        <Route exact path={`${path}/FA`}>
          <FAInputSubInspector {...{ elementData, elementId, elementType, updateData, isDirty }} />
        </Route>
        <Route exact path={`${path}/SFTP`}>
          <SFTPInputSubInspector {...{ elementData, elementId, elementType, updateData, isDirty }} />
        </Route>
      </Switch>
    </ElementInspector>
  );
};

export default InputElementInspector;
