import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DataFlowSaveChangesModal from './DataFlowSaveChangesModal';
import { useClient } from '../../../../contexts/ClientContext';
import { getTokenInfo } from '../../../../components/_shared/auth';

const CONFIRMATION_MESSAGE =
  'The Data Connect workflow has unsaved changes. If you leave before saving, your changes will be lost. What would you like to do?';

const SaveChangesPrompt = ({ when }) => {
  const history = useHistory();
  const { clientId } = useClient();
  const prevClientIdRef = useRef(clientId);
  const [showDialog, setShowDialog] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [confirmNavigation, setConfirmNavigation] = useState(false);

  useEffect(() => {
    if (!when) {
      return;
    }

    const handleBeforeUnload = event => {
      /**
       * Bypass confirmation popup when user already logout
       */
      const tokenInfo = getTokenInfo();
      if (!tokenInfo) {
        return;
      }

      event.preventDefault();
      if (!event) {
        event.returnValue = CONFIRMATION_MESSAGE;
      }
      return CONFIRMATION_MESSAGE;
    };

    const unblock = history.block((location, action) => {
      // Prevent to show dialog on the editor
      const editorPathRgx = /^\/data-flows\/[0-9a-fA-F-]+\/editor\/?.*$/;
      const isAllowedPath = editorPathRgx.test(location.pathname);

      if (!isAllowedPath && !showDialog) {
        setShowDialog(true);
        setNextLocation(location);
        return false;
      } else {
        return null;
      }
    });

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblock();
    };
  }, [when, showDialog, history]);

  const handleConfirmNavigationClick = () => {
    setShowDialog(false);
    setConfirmNavigation(true);
    history.push(nextLocation.pathname);
  };

  useEffect(() => {
    if (confirmNavigation && nextLocation) {
      history.push(nextLocation.pathname);
    }
  }, [confirmNavigation, nextLocation, history]);

  /**
   * Handling client change to close confirmation modal
   */
  useEffect(() => {
    if (clientId && prevClientIdRef.current !== clientId) {
      setShowDialog(false);
      setConfirmNavigation(true);
    }

    prevClientIdRef.current = clientId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  return (
    <DataFlowSaveChangesModal
      showConfirm={showDialog}
      onCloseConfirm={() => setShowDialog(false)}
      onConfirm={handleConfirmNavigationClick}
    />
  );
};

export default SaveChangesPrompt;
