function isCellOnScreenValidator({ GC, sheet, row, col, onNotActive }) {
  return () => {
    let active = false;
    try {
      const { x, y, width, height } = sheet.getCellRect(row, col);
      active = x > 0 && y > 0 && width > 0 && height > 0;
      if (active) {
        const spread = GC.Spread.Sheets.findControl(document.querySelector('[gcuielement="gcSpread"]'));
        const activeSheet = spread.getActiveSheet();
        active = activeSheet.name() === sheet.name();
      }
    } catch (_) {}

    if (!active) {
      onNotActive();
    }

    return active;
  };
}

export { isCellOnScreenValidator };
