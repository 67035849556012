import { userDataAPIPath } from '../../../configs/params';
import * as fetch from '../../_shared/fetch';

export async function getClients() {
  const res = await fetch.get(`${userDataAPIPath}/client`);

  if (res.status !== 200) {
    throw new Error('Error trying to get clients');
  }

  return await res.json();
}

export async function createUserToken(clientId) {
  //TODO HANDLE ERROR
  await fetch.post(`${userDataAPIPath}/client/token`, {
    body: JSON.stringify({ clientId }),
  });
}
