import React, { useContext, useEffect, useState } from 'react';
import { BTButton, BTAlert } from '@btas/jasper';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { DF_INPUT_FA_OPTION, DF_INPUT_SFTP_OPTION, WKP_INPUT_FILE_IMPORT } from '../../../../constants/featureFlags';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { isValidFAUser } from './apis';
import { faLearnMoreLink } from '../../../../configs/params';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';
import WKPInputFileImport from './WKPInputFileImport';
import FileDialog from '../FileDialog/FileDialog';
import Spinner from '../../../_shared/Spinner';
import {
  FA_INTEGRATION_TYPE,
  SFTP_INTEGRATION_TYPE,
  WKP_IMPORT_INTEGRATION_TYPE,
  WKP_INTEGRATION_TYPE,
} from './constants';
import GeneralErrorMessage from '../../../_shared/GeneralErrorMessage';
import FileValidationMessage from '../FileValidationMessage';
import { useTrackInteractiveClick } from '../../../_shared/EventTrackingContext';
import { itemTypes } from '../../../_shared/EventTrackingContext/utils';

const InputOptionsSubInspector = ({ updateData, uploadingElementId }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { dataFlowState, dataFlowActions, showConfirmationModal, sourceFileUpload } = useContext(DataFlowEditorContext);
  const { setFAInputProperties, setSFTPInputProperties, setFilePropertiesDialog } = dataFlowActions;
  const { faInputProperties, sftpInputProperties, taxPeriod, workingElement } = dataFlowState;
  const { uploadToS3Error, setUploadToS3Error, uploadValidationError } = sourceFileUpload;

  const [generalError, setGeneralError] = useState({
    message: undefined,
  });

  const trackEvent = useTrackInteractiveClick();

  useEffect(() => {
    updateData({ integrationType: '' });
  }, [updateData]);

  useEffect(() => {
    if (uploadToS3Error) {
      //Automatically dismiss generic error message after 5 seconds
      setTimeout(() => {
        setUploadToS3Error(undefined);
      }, 5000);
    }
  }, [uploadToS3Error, setUploadToS3Error]);

  const updateIntegrationType = async type => {
    if (type === FA_INTEGRATION_TYPE) {
      const hasPermission = await isValidFAUser();
      if (!hasPermission) {
        showConfirmationModal(
          'Unable to Connect to Bloomberg Tax Fixed Assets',
          'Either you have not purchased Bloomberg Tax Fixed Assets or this feature is not available at your service level.',
          'LEARN MORE',
          'Cancel',
          '',
          () => {
            window.open(faLearnMoreLink, '_blank', 'noreferrer');
          }
        );
        return;
      } else {
        updateData({ integrationType: type });
        setFAInputProperties({ ...faInputProperties, showDataType: false });
      }
    }
    if (type === SFTP_INTEGRATION_TYPE) {
      updateData({ integrationType: type });
      setSFTPInputProperties({ ...sftpInputProperties, showDataType: false });
    }
    history.push(`${url}/${type}`);
  };

  const handleDismissAlert = () => {
    setGeneralError(null);
  };

  const onSubmit = async () => {
    trackEvent('reuse-existing-source-file', itemTypes.BUTTON);
    setFilePropertiesDialog({
      popup: true,
      loading: false,
      uploadExistingFile: true,
      fileDialogTaxPeriod: taxPeriod,
      sourceInfo: {
        dataFlowId: dataFlowState.id,
        inputId: workingElement.id,
        elementData: { ...workingElement.elementData, containsNewSourceFiles: true },
      },
      reloadFiles: true,
    });
  };

  const renderUploadOptions = () => {
    return (
      <>
        <h5>Data Source</h5>
        <div>
          {canEditWorkflow && !isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) && (
            <BTButton
              btStyle="default"
              className={'wkp-df-inputBlock-buttons'}
              id="uploadWKPFileBtn"
              name="uploadWKPFileBtn"
              onClick={() => updateIntegrationType(WKP_INTEGRATION_TYPE)}
            >
              Upload a file (.xlsx, .csv)
            </BTButton>
          )}

          {canEditWorkflow && isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) && (
            <WKPInputFileImport
              updateIntegrationType={(type = WKP_IMPORT_INTEGRATION_TYPE) => updateIntegrationType(type)}
            />
          )}

          {canEditWorkflow && isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) && (
            <div>
              <BTButton
                btStyle="default"
                className={'wkp-df-inputBlock-buttons'}
                id="attach-existing-source-file-btn"
                name="uploadFAFileBtn"
                onClick={onSubmit}
              >
                Attach Existing Source File
              </BTButton>
              <FileDialog
                dataFlowActions={dataFlowActions}
                dataFlowState={dataFlowState}
                setGeneralError={setGeneralError}
              />
              <BTAlert
                appear
                dismissible
                fixed
                btStyle="danger"
                visible={generalError?.message !== undefined}
                onDismiss={handleDismissAlert}
              >
                {generalError ? generalError?.message : ''}
              </BTAlert>
            </div>
          )}
          {canEditWorkflow && isFeatureFlagEnabled(DF_INPUT_FA_OPTION) && (
            <BTButton
              btStyle="default"
              className={'wkp-df-inputBlock-buttons'}
              id="uploadFAFileBtn"
              name="uploadFAFileBtn"
              onClick={() => {
                updateIntegrationType(FA_INTEGRATION_TYPE);
                trackEvent('Bloomberg Tax Fixed Assets Import Button', itemTypes.BUTTON);
              }}
            >
              Bloomberg Tax Fixed Assets
            </BTButton>
          )}

          {canEditWorkflow && isFeatureFlagEnabled(DF_INPUT_SFTP_OPTION) && (
            <BTButton
              btStyle="default"
              className={'wkp-df-inputBlock-buttons'}
              id="uploadSftpFileBtn"
              name="uploadSftpFileBtn"
              onClick={() => {
                updateIntegrationType(SFTP_INTEGRATION_TYPE);
                trackEvent('Secure File Transfer Protocol (SFTP) Import Button', itemTypes.BUTTON);
              }}
            >
              Secure File Transfer Protocol (SFTP)
            </BTButton>
          )}
        </div>
      </>
    );
  };

  const renderMultiUploadJobWarning = () => {
    return (
      <div className="wkp-inspector-warning">
        <BTAlert key={workingElement.id} btStyle={'warning'}>
          <p>Unable to upload another file until the current import is complete.</p>
        </BTAlert>
      </div>
    );
  };

  const renderUploadPanel = () => {
    if (
      uploadingElementId &&
      uploadingElementId !== workingElement?.id &&
      !workingElement?.elementData?.hasSourceFileUploaded
    ) {
      return renderMultiUploadJobWarning();
    }

    if (workingElement?.elementData?.hasSourceFileUploaded) {
      return <Spinner />;
    } else {
      return renderUploadOptions();
    }
  };

  const canEditWorkflow = useCanEditWorkflow();

  return (
    <>
      <div className="wkp-input-element-inspector">{renderUploadPanel()}</div>

      {isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) && (
        <GeneralErrorMessage dismissible={true} visible={!!uploadToS3Error} />
      )}
      {isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) && (
        <FileValidationMessage dismissible={true} message={uploadValidationError} visible={!!uploadValidationError} />
      )}
    </>
  );
};

export default InputOptionsSubInspector;
