import { BTButton } from '@btas/jasper';
import React from 'react';
import Frame from '../_shared/Frame';
import './ClientChange/styles.scss';

export default function ClientChange() {
  return (
    <Frame showAppBar={false}>
      <div className="client-change-container">
        <div>
          <h1>You have changed clients</h1>
          <div className="client-change-error-message-text">
            The client selection was changed in a separate window causing the session for the previously selected client
            to close. Return
            <br />
            to the Bloomberg Tax Workpapers home page to continue to the newly selected client.
          </div>

          <div className="client-change-btn-container">
            <a href="/">
              <BTButton btStyle="primary" className="client-change-homepage-btn">
                RETURN TO WORKPAPERS HOME
              </BTButton>
            </a>
          </div>
        </div>
      </div>
    </Frame>
  );
}
