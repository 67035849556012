import { TransformationElementType } from '../TransformationElementType';
import { JoinElementInspector, JoinInput, JoinOutput } from '../../JoinElementInspector';
import { JOIN_COLOR } from '../../shared/colors';
import { computeKeyFieldAutoMappings } from './shared/utils';
import iconImage from '../icons/join_block_icon.svg';
import hintImage from '../icons/join_hint_img.svg';
import { JOIN } from '../types/shared/typesConstants';
import { getOriginalName } from '../../../shared/utils/FieldHashUtils';
import { OutputToggleUtils } from '../../DataFlowOutputPreview/OutputToggleUtils';

export class JoinElementType extends TransformationElementType {
  static TYPE = JOIN;

  static HELP_TEXT = `The join block places two inputs side-by-side to enable tasks like computing the difference or sum. Rows will be matched based on a common identifier.<img src=${hintImage} alt="Join hint" />`;

  constructor() {
    super(JoinElementType.TYPE, 'Join', JOIN_COLOR, iconImage, JoinElementType.HELP_TEXT, true);

    this._outputToggleUtils = new OutputToggleUtils();
  }

  get initialData() {
    return {
      name: this.label,
      type: this.type,
      keyFields: [{ left: null, right: null }],
      picked: { A: {}, B: {} },
      renamed: { A: {}, B: {} },
      outputToUnion: { A: true, B: true },
    };
  }

  get maxCount() {
    return -1;
  }

  get inPorts() {
    return [JoinInput.A, JoinInput.B];
  }
  // J port goes first to be used as defaulted value
  get outPorts() {
    return [JoinOutput.J, JoinOutput.A, JoinOutput.B];
  }

  get inspectorComponent() {
    return JoinElementInspector;
  }

  getPreviewColumns(elementData, previewOutputNode) {
    if (!elementData.fields) {
      return [];
    }

    let port = this._outputToggleUtils.getValidJoinOutput(previewOutputNode, elementData.name);

    if (port === 'A') {
      port = 'a';
    }
    if (port === 'B') {
      port = 'b';
    }
    let result = elementData.fieldsByPort[port];
    return result;
  }

  applySourceElements(elementData, sourceElements) {
    const leftFields = sourceElements['A']?.elementData?.fields ?? [];
    const rightFields = sourceElements['B']?.elementData?.fields ?? [];

    /**
     * If there is some configuration in the block when disconnected,
     * let it as is
     */
    if (
      this.areMappingsFilled(elementData.keyFields) &&
      (sourceElements['A'] === undefined || sourceElements['B'] === undefined) &&
      elementData?.fieldsByPort
    ) {
      return elementData;
    }

    const keyFields = this.areMappingsFilled(elementData.keyFields)
      ? elementData.keyFields
      : computeKeyFieldAutoMappings(elementData.keyFields, leftFields, rightFields);

    if (keyFields?.length > 0 && leftFields && rightFields) {
      keyFields?.forEach(mapping => {
        const leftMappings = mapping?.left;
        const rightMappings = mapping?.right;

        const leftField = leftFields.find(
          field => leftMappings?.name === field.name && leftMappings?.type !== field.type
        );
        if (leftField) {
          leftMappings.type = leftField.type;
        }

        const rightField = rightFields.find(
          field => rightMappings?.name === field.name && rightMappings?.type !== field.type
        );
        if (rightField) {
          rightMappings.type = rightField.type;
        }
      });
    }

    const rightKeyField = keyFields?.map(field => field.right?.name);

    const picked = {
      A: this.reconcilePickedLeft(leftFields, elementData.picked?.A),
      B: this.reconcilePickedRight(rightFields, elementData.picked?.B, rightKeyField),
    };

    const renamedLeft = this.produceLeftRename(leftFields, picked.A, elementData.renamed?.A);
    // prettier-ignore
    const renamedRight = this.produceRightRename(rightFields, picked.B, elementData.renamed?.B, renamedLeft.fields);
    const renamed = { A: renamedLeft?.renames, B: renamedRight?.renames };
    const fields = renamedRight?.fields;
    const fieldsByPort = { J: fields || [], a: leftFields || [], b: rightFields || [] };

    return {
      ...elementData,
      keyFields: keyFields.length > 0 ? keyFields : this.initialData.keyFields,
      picked,
      renamed,
      fields,
      fieldsByPort,
      outputToUnion: {
        A: elementData.outputToUnion?.A ?? true,
        B: elementData.outputToUnion?.B ?? true,
      },
    };
  }

  extractTypeData(elementData) {
    return {
      ...super.extractTypeData(elementData),
      keyFields: elementData.keyFields,
      picked: elementData.picked,
      renamed: elementData.renamed,
      outputPorts: this.outPorts.length,
      outputToUnion: elementData.outputToUnion,
    };
  }

  reconcilePickedLeft(fields, picked = {}) {
    return fields.reduce((acc, { name }) => {
      acc[name] = picked[name] ?? true;
      return acc;
    }, {});
  }

  reconcilePickedRight(fields, picked = {}, keyFields) {
    return fields.reduce((acc, { name }) => {
      if (keyFields.includes(name)) {
        acc[name] = false;
      } else {
        acc[name] = picked[name] ?? true;
      }
      return acc;
    }, {});
  }

  addPortsToInstance(instance) {
    if (this.inPorts) {
      this.inPorts.forEach(inPort => {
        instance.addInPort(inPort);
      });
    }

    if (this.outPorts) {
      // outPorts array arranges ports in order
      let outPorts = ['a', 'J', 'b'];
      outPorts.forEach(outPort => {
        instance.addOutPort(outPort);
      });
      instance.portProp('a', 'attrs', { '.port-label': { text: 'A' } });
      instance.portProp('b', 'attrs', { '.port-label': { text: 'B' } });
    }
  }

  areMappingsFilled(mappings) {
    const [mapping] = mappings;

    return mapping.left !== null && mapping.right !== null;
  }

  produceLeftRename(fields, picked = {}, renames = {}) {
    return fields.reduce(
      (acum, field) => {
        if (!picked[field.name]) {
          return acum;
        }

        if (renames[field.name]) {
          acum.renames[field.name] = renames[field.name];
          acum.fields.push({ ...field, original_name: undefined, name: renames[field.name] });
        } else {
          acum.fields.push(field);
        }

        return acum;
      },
      { renames: {}, fields: [] }
    );
  }

  produceRightRename(fields, picked = {}, renames = {}, leftFields = []) {
    const currentFieldNames = leftFields.reduce((set, f) => {
      set.add(getOriginalName(f));

      return set;
    }, new Set());

    return fields.reduce(
      (acum, field) => {
        let name = renames[field.name] || getOriginalName(field);

        while (currentFieldNames.has(name)) {
          name = 'B_' + name;
        }

        const isRenamed = name !== getOriginalName(field);

        // If the field has been renamed
        if (isRenamed) {
          // Add it to the rename mappings
          acum.renames[field.name] = name;
        }

        if (picked[field.name]) {
          currentFieldNames.add(name);

          if (isRenamed) {
            acum.fields.push({ ...field, original_name: undefined, name: name });
          } else {
            acum.fields.push(field);
          }
        }

        return acum;
      },
      { renames: {}, fields: [...leftFields] }
    );
  }
}
