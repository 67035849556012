import React, { useEffect, useState, useCallback, useContext } from 'react';
import { getUser, getUserInfo } from './auth';
import Tracker from './EventTrackingContext/Tracker';
import eventConfigurations from './EventTrackingContext/eventConfiguration';
import { getFakeEvent, setPropValues } from './EventTrackingContext/utils';
import { eventNames } from './EventTrackingContext/constants';
import CustomLogger from './Logger/CustomLogger';
import { getProductCodes } from './getProductCodes';

const EventTrackingContext = React.createContext();

const CLICK_EVENT_ERROR_MESSAGE = `Cannot send data to amplitude for ${eventNames.interactiveClick} because provider context was not found`;

const EventTrackingProvider = ({ children }) => {
  const userInfo = getUser();
  const userInfoV2 = getUserInfo();
  const productCodes = getProductCodes();
  const [tracker, setTracker] = useState(null);

  useEffect(() => {
    const user = userInfoV2?.designatedUser || userInfoV2?.sharedUser;

    // commenting this line so you can test locally
    //const initializeTracker = user && productCodes && !tracker;
    const initializeTracker = !tracker;

    if (initializeTracker) {
      userInfo.accountType = user?.cti;
      userInfo.indgUnitNumber = user?.unitNo;
      userInfo.indgUserId = user?.userId;
      userInfo.internalUserFlag = user?.internalUserFlag;
      userInfo.productCodes = productCodes;
      setTracker(new Tracker(userInfo));
    }
  }, [userInfo, userInfoV2?.designatedUser, userInfoV2?.sharedUser, productCodes, tracker]);

  useEffect(() => {
    const handleEvent = (event, eventName, tagPropertiesMapping) => {
      const tag = event.target.getAttribute('data-track-tag');
      if (tag && tagPropertiesMapping[tag] && tracker) {
        const isValidEvent = tracker.isValid(eventName);
        if (isValidEvent) {
          tracker.track(eventName, setPropValues(event, eventName, tagPropertiesMapping[tag]));
        } else {
          console.error(`[TrackingSupport] Error: '${eventName}' not valid`);
        }
      }
    };

    const addEventListeners = () => {
      eventConfigurations.forEach(({ eventType, eventName, tagPropertiesMapping }) => {
        document.addEventListener(eventType, event => handleEvent(event, eventName, tagPropertiesMapping));
      });
    };

    const removeEventListeners = () => {
      eventConfigurations.forEach(({ eventType, eventName, tagPropertiesMapping }) => {
        document.removeEventListener(eventType, event => handleEvent(event, eventName, tagPropertiesMapping));
      });
    };

    addEventListeners();
    return removeEventListeners;
  }, [tracker]);

  // function to manually trigger tracking events
  const trackEvent = (eventName, properties) => {
    if (tracker && tracker.isValid(eventName)) {
      tracker.track(eventName, properties);
    } else {
      console.error(`[TrackingSupport] Error: '${eventName}' not valid or tracker not initialized`);
    }
  };

  return <EventTrackingContext.Provider value={{ trackEvent }}>{children}</EventTrackingContext.Provider>;
};

export { EventTrackingProvider, EventTrackingContext };

export function useTrackInteractiveClick() {
  const eventTrackingContextData = useContext(EventTrackingContext);
  const callback = useCallback(
    (itemClicked, itemType) => {
      const { trackEvent } = eventTrackingContextData;

      if (trackEvent) {
        const fakeEvent = getFakeEvent(itemType, itemClicked);

        trackEvent(eventNames.interactiveClick, setPropValues(fakeEvent, eventNames.interactiveClick, null));
      } else {
        CustomLogger.pushLog(CustomLogger.operations.SEND_TO_AMPLITUDE, {
          error: CLICK_EVENT_ERROR_MESSAGE,
        });
        console.error(CLICK_EVENT_ERROR_MESSAGE);
      }
    },
    [eventTrackingContextData]
  );
  return callback;
}
