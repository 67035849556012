import { useEffect, useState } from 'react';
import { getClients } from '../components/home/ClientSelection/apis';

export function useGetClients() {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clients = await getClients();
        setClients(clients);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchClients();
  }, []);

  return { clients, isLoading, error };
}
