import { CELL_REVIEW, DATA_LINK } from '../../../_shared/DataReference/ReferenceType';
import { cleanDirtyCellValue, setCellTag } from './dataReferenceHelper';
import { toA1Notation } from '../../EditorPage/useDataLink';
import { BroadcastChannels, EventTypes } from '../useBroadcast/constants';

/**
 * This hook handles cell tracking update logic
 */
export function useCellTrackerUpdate({
  dataReferences,
  workpaperIdRef,
  dataReferencePositionUpdateQueue,
  processDataReferencePositionQueue,
  lastCellReviewActionTimestamp,
  updateCellReferencesTags,
  enqueueCellTagUpdateQueue,
  sendMessage,
}) {
  function updateSheetReferencesFromCells(sheet, sheetNames) {
    const valueReferences = [];

    if (sheetNames && sheetNames?.length) {
      for (let i = 0; i < sheetNames.length; i++) {
        const cellReviewReferences = [];

        const sheetName = sheetNames[i];

        const currentSheet = sheet?.getParent()?.getSheetFromName(sheetName);

        const referenceDependentCells = currentSheet?.getDirtyCells();

        const sheetCellReviewReferences = dataReferences?.current?.filter(
          r => r.sheetName === sheetName && r.type === CELL_REVIEW
        );

        referenceDependentCells?.length &&
          referenceDependentCells.forEach(x => {
            cellReviewReferences.push(...sheetCellReviewReferences?.filter(y => y.row === x.row && y.column === x.col));
          });

        cellReviewReferences?.length &&
          cellReviewReferences.forEach(x => {
            const cellValue = cleanDirtyCellValue(currentSheet.getCell(x.row, x.column).text());
            if (x && x.value !== cellValue && !valueReferences.find(y => y.id === x.id)) {
              valueReferences.push({ ...x, value: cellValue });
            }
          });
      }
    }

    if (valueReferences.length) {
      updateDataReferences(valueReferences);
      enqueueCellTagUpdateQueue(valueReferences);
    }
  }

  function updateReferencesFromCells(sheetReferences, sheet, row, col, valueReferences) {
    const cellReferences = sheetReferences.filter(r => {
      if (r.referenceType === DATA_LINK && r.workpaperId !== workpaperIdRef.current) {
        const { row: rowSource, column } = typeof r.parameters === 'string' ? JSON.parse(r.parameters) : r.parameters;

        return row === rowSource && column === col;
      }

      return r.row === row && r.column === col;
    });

    if (cellReferences?.length) {
      const cellReviewReferences = cellReferences.filter(x => x.type === CELL_REVIEW);
      const notCellReviewReferences = cellReferences.filter(x => x.type !== CELL_REVIEW);
      let cellTag = null;

      if (cellReviewReferences?.length) {
        const value = sheet.getCell(row, col).text();
        const cellValue = cleanDirtyCellValue(value);

        cellTag = {
          value: cellValue,
          references: cellReviewReferences.map(x => {
            valueReferences.push({ ...x, value: cellValue });
            return { type: x.type, id: x.id };
          }),
        };
      } else {
        cellTag = null;
      }
      setCellTag(sheet, row, col, cellTag);

      updateCellReferencesTags.push({
        row: row,
        column: col,
        value: null,
        sheetName: sheet.name(),
      });

      if (notCellReviewReferences?.length) {
        return notCellReviewReferences.map(x => x.id);
      }
      return null;
    }
  }

  function updateDataReferenceCellPositions(positionReferences, type, filter, operation, row, column, count) {
    const referencesPositionsToUpdate = [];

    positionReferences.forEach(reference => {
      let filterPosition;
      let position;
      let isWithinSelection = false;
      let isWithinRange = false;
      let parameters =
        reference.type === DATA_LINK
          ? typeof reference.parameters === 'string'
            ? JSON.parse(reference.parameters)
            : reference.parameters
          : null;

      if (filter === 'row') {
        filterPosition = reference.type === DATA_LINK ? parameters.row : reference.row;
        position = row;
        isWithinRange = (reference.type === DATA_LINK ? parameters.row : reference.column) >= column;
      } else if (filter === 'column') {
        filterPosition = reference.type === DATA_LINK ? parameters.column : reference.column;
        position = column;
        isWithinRange = (reference.type === DATA_LINK ? parameters.row : reference.row) >= row;
      }

      if (operation === 'equal') {
        isWithinSelection = filterPosition === position;
      } else if (operation === 'greater-equal') {
        isWithinSelection = filterPosition >= position;
      }

      if (type === 'row' && isWithinSelection === true && isWithinRange === true) {
        const updatedRowCount = reference.row + count;
        if (updatedRowCount >= 0) {
          if (reference.type === DATA_LINK) {
            parameters.row = parameters.row + count;
            parameters.a1Coordinate = toA1Notation(parameters.row, parameters.column);
            const updatedReference = { ...reference, parameters };
            sendMessage(BroadcastChannels.SourceUpdateReferences, {
              type: EventTypes.DataLinkSourceUpdated,
              details: [updatedReference],
            });
            return referencesPositionsToUpdate.push(updatedReference);
          }
          return referencesPositionsToUpdate.push({ ...reference, row: updatedRowCount });
        }
      } else if (type === 'column' && isWithinSelection === true && isWithinRange === true) {
        const updatedColumnCount = reference.column + count;
        if (updatedColumnCount >= 0) {
          if (reference.type === DATA_LINK) {
            parameters.column += count;
            parameters.a1Coordinate = toA1Notation(parameters.row, parameters.column);
            const updatedReference = { ...reference, parameters };
            sendMessage(BroadcastChannels.SourceUpdateReferences, {
              type: EventTypes.DataLinkSourceUpdated,
              details: [updatedReference],
            });
            return referencesPositionsToUpdate.push(updatedReference);
          }
          return referencesPositionsToUpdate.push({ ...reference, column: updatedColumnCount });
        }
      } else if (type === 'rowcolumn') {
        if (reference.type === DATA_LINK) {
          parameters.column += count.columnCount;
          parameters.row += count.rowCount;
          parameters.a1Coordinate = toA1Notation(parameters.row, parameters.column);
          const updatedReference = { ...reference, parameters };
          sendMessage(BroadcastChannels.SourceUpdateReferences, {
            type: EventTypes.DataLinkSourceUpdated,
            details: [updatedReference],
          });
          return referencesPositionsToUpdate.push(updatedReference);
        }
        return referencesPositionsToUpdate.push({
          ...reference,
          column: reference.column + count.columnCount,
          row: reference.row + count.rowCount,
        });
      }
    });

    return referencesPositionsToUpdate;
  }

  function updateDataReferences(referencesToUpdate) {
    dataReferencePositionUpdateQueue.current = [...dataReferencePositionUpdateQueue.current, ...referencesToUpdate];
    processDataReferencePositionQueue();

    if (referencesToUpdate && referencesToUpdate.length > 0) {
      const updatedInMemoryReference = [];
      dataReferences.current.forEach(reference => {
        const updatedReference = referencesToUpdate.find(x => x.id === reference.id);
        if (updatedReference) {
          const isDataLinkSource = updatedReference.workpaperId !== workpaperIdRef.current;
          const hasChanges =
            updatedReference.row !== reference.row ||
            updatedReference.column !== reference.column ||
            updatedReference.value !== reference.value;
          const isCellReview = updatedReference.type === CELL_REVIEW;

          if (hasChanges || isDataLinkSource) {
            updatedInMemoryReference.push(updatedReference);
          }
          if (isCellReview) {
            lastCellReviewActionTimestamp.current = Date.now();
          }
        } else {
          updatedInMemoryReference.push(reference);
        }
      });
      dataReferences.current = updatedInMemoryReference;

      if (updateCellReferencesTags && updateCellReferencesTags.length > 0) {
        enqueueCellTagUpdateQueue(updateCellReferencesTags);
      }
    }
  }

  return {
    updateSheetReferencesFromCells,
    updateReferencesFromCells,
    updateDataReferenceCellPositions,
    updateDataReferences,
  };
}
