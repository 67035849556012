import * as fetch from '../../../_shared/fetch';
import { dataFlowServiceHostName } from '../../../../configs/params';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import { getJobsStatus, startJob } from '../../../_shared/jobs/apis';
import { UPLOAD_OUTPUT } from '../../../_shared/jobs/jobTypes';
import { WORKPAPER_JOB_STATUS_COMPLETED, WORKPAPER_JOB_STATUS_FAILED } from '../../../_shared/jobs/jobStatus';
import { getDataFromLocalStorage, removeDataFromLocalStorage, setDataToLocalStorage } from '../../../_shared/storage';

export async function removePendingData(dataFlowId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/pending`;
  const res = await fetch.remove(reqUri);

  return res.json();
}

export async function removeDataFlow(dataFlowId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows`;
  const res = await fetch.remove(reqUri, {
    body: JSON.stringify({ ids: [dataFlowId] }),
  });

  return res.json();
}

export const getPollingJobResult = async (dataFlowId, jobId, customHandler) => {
  /* eslint-disable no-loop-func */
  let processIsRunning = true;
  let parsedResult = {};
  let delay = 1000;
  let growthFactor = 1.05; //Increase 5% each time

  customHandler();
  while (processIsRunning) {
    const response = await getJobsStatus({ jobIds: [jobId] });
    const jobResult = response[0];
    // Verify if job is completed or failed
    if (jobResult.status === WORKPAPER_JOB_STATUS_COMPLETED || jobResult.status === WORKPAPER_JOB_STATUS_FAILED) {
      processIsRunning = false;
      parsedResult = JSON.parse(jobResult.payload);
      const status = jobResult.status === WORKPAPER_JOB_STATUS_COMPLETED ? 'was successful' : 'failed';
      CustomLogger.pushLog(CustomLogger.operations.POLLING_JOB, {
        workflow_id: dataFlowId,
        job_id: jobId,
        message: `Polling response ${status}`,
      });
      if (jobResult.status === WORKPAPER_JOB_STATUS_FAILED) {
        throw new Error(`The endpoint processing has failed.`);
      }
    } else {
      await new Promise(resolve => setTimeout(resolve, delay));
      delay = delay * growthFactor;
    }
  }
  return parsedResult;
};

export const uploadedData = async (dataFlowRunId, jobId, dataFlowId, uint8Array) => {
  await fetch.post(`${dataFlowServiceHostName}/api/v1/data-flows/data-flow-run/${dataFlowRunId}/upload-output`, {
    body: JSON.stringify({ fileData: Array.from(uint8Array), jobId, dataFlowId }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export async function uploadOutputRecords(dataFlowRunId, dataFlowId, pngFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async () => {
      const uploadJobIdKey = 'uploadJobIdKey';
      let jobId;
      try {
        const arrayBuffer = reader.result;
        const uint8Array = new Uint8Array(arrayBuffer);

        jobId = getDataFromLocalStorage(uploadJobIdKey);
        if (!jobId) {
          const jobResponse = await startJob({
            entityId: dataFlowRunId,
            jobType: UPLOAD_OUTPUT,
            payload: null,
            batchId: dataFlowId,
          });
          jobId = jobResponse?.jobId;
          setDataToLocalStorage(uploadJobIdKey, jobId);
          CustomLogger.pushLog(CustomLogger.operations.SAVE_AND_PUBLISH, {
            workflow_id: dataFlowId,
            job_id: jobId,
            message: `Starting the polling mechanism to retrieve the response from upload-output endpoint`,
          });
        }
        const response = await getPollingJobResult(dataFlowId, jobId, () =>
          uploadedData(dataFlowRunId, jobId, dataFlowId, uint8Array)
        );
        removeDataFromLocalStorage(uploadJobIdKey);
        resolve(response);
      } catch (error) {
        removeDataFromLocalStorage(uploadJobIdKey);
        CustomLogger.pushLog(CustomLogger.operations.PNG_AUDIT_SNAPSHOT_UPLOAD, {
          message: `POST request catastrophically failed to the upload-output endpoint ${JSON.stringify(error)}`,
        });
        reject(error);
      }
    };
    reader.onerror = () => reject(new Error('PNG snapshot read error during save and publish'));
    reader.readAsArrayBuffer(pngFile);
  });
}
