// TODO: This don't need to be a hook, it's just a function
export function useUserProfile() {
  const isAdmin = () => {
    const tokenInfo = localStorage.getItem('token-info');
    if (!tokenInfo) return false;

    const userInfo = JSON.parse(tokenInfo);
    const isUserAdmin = userInfo.preferences?.some(
      pref => pref.scope === 'cpoc' && pref.type === 'role' && pref.value === 'admin'
    );

    return isUserAdmin;
  };

  return {
    isAdmin,
  };
}
