export class UserLogoutSyncService {
  constructor() {
    this.STORAGE_KEY = 'token-info';
  }

  subscribe(callback) {
    const handleStorageChange = event => {
      if (event.key === this.STORAGE_KEY && !event.newValue) {
        callback();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }
}
