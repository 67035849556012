export const eventNames = {
  interactiveClick: 'interactiveClick',
  navigation: 'navClicked',
  fileUploaded: 'fileUploaded',
  feedbackForms: 'feedbackFormSubmitted',
  toolsContent: 'embeddedContent',
  docExported: 'documentExported',
};

export const eventTypes = {
  click: 'click',
  change: 'change',
  submit: 'submit',
  keyDown: 'keyDown',
};

export const eventTrackTagNames = {
  interactive: 'interactive',
  navClicked: 'navClicked',
  fileUpload: 'fileUpload',
  feedback: 'feedbackSubmit',
  wkpTools: 'tools',
  documentExported: 'documentExported',
};
