/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, useMemo } from 'react';
import { BTIcon } from '@btas/jasper';
import { useLocation, Link } from 'react-router-dom';
import feedFormbackIcon from '../FakeBanner/feedback_form_icon.svg';
import './styles.scss';
import { getUser, logout } from '../../auth';
import Spinner from '../../Spinner';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { FEEDBACK_FORM, RESEARCH_AI } from '../../../../constants/featureFlags';
import { applications } from './applications';
import TaxResearchAi from '../TaxResearchAi';
import CmAdminMenu from '../CmAdminMenu/CmAdminMenu';
import { useTrackInteractiveClick } from '../../EventTrackingContext';
import { itemTypes } from '../../EventTrackingContext/utils';
import ClientPickerMenu from '../ClientPickerMenu/ClientPickerMenu';
import DataFlowSaveChangesModal from '../../../data-flows/shared/DataFlowSaveChangesModal/DataFlowSaveChangesModal';

const CmFakeBanner = ({ onOpenFeedbackFormClick, isDataFlowDirty }) => {
  const userInfo = getUser();
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const trackEvent = useTrackInteractiveClick();

  const userInfoDisplay = useMemo(() => {
    const userRole = userInfo?.preferences?.find(pref => pref?.scope === 'workpaper');
    return userInfo
      ? `${userInfo.firstName} ${userInfo.lastName ? userInfo.lastName : ''} ${userRole ? '| Role: ' + userRole?.value?.charAt(0).toUpperCase() + userRole?.value?.slice(1) : ''}`
      : '';
  }, [userInfo]);

  const DATA_TRANSFORMATION_PATH = '/data-flows';

  const CLIENT_SELECTION_PATH = '/client-selection';

  const pathName = useLocation().pathname;

  const onCloseConfirm = useCallback(() => setShowConfirm(false), [setShowConfirm]);
  const onClickConfirm = useCallback(() => {
    setShowConfirm(false);
    onLogout();
  }, [setShowConfirm]);

  const onLogout = () => {
    setLoading(true);
    logout();
  };

  const onLogoutConfirm = useCallback(() => {
    if (isDataFlowDirty) {
      setShowConfirm(true);
    } else {
      onLogout();
    }
  }, [isDataFlowDirty, setShowConfirm]);

  const handleClick = useCallback(
    event => {
      if (isDataFlowDirty && event?.target?.target !== '_blank') {
        event.preventDefault();
        window.location = event?.target?.href;
      }
    },
    [isDataFlowDirty]
  );

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="cm-fake-banner-wrapper">
        <nav className="navbar bbna-navbar-hat-slim" id="bbnaTopNav">
          <div>
            <ul className="nav navbar-nav">
              <li className="bbna-tiles-container dropdown">
                <a
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  href="#"
                  id="app-picker"
                  title="Applications"
                  type="button"
                >
                  <BTIcon className="bbna-icon-apps" icon="apps" />
                </a>

                <ul aria-labelledby="app-picker" className="dropdown-menu dropdown-menu-apps" role="menu">
                  <li aria-level="2" className="dropdown-header applications" role="heading">
                    APPLICATIONS
                  </li>
                  {applications.map((item, index) => (
                    <a
                      key={index}
                      className={`app-link ${item.link === '/data-flows' ? 'active' : ''}`}
                      data-testid={item.testId}
                      href={item.link}
                      rel="noreferrer"
                      role="menuitem"
                      target={item.link !== '/data-flows' && '_blank'}
                      onClick={handleClick}
                    >
                      {item.icon}
                      <span className="name-menu-apps">{item.name}</span>
                    </a>
                  ))}
                </ul>
              </li>

              <li className="dropdown">
                <Link className="brand-link" to="/">
                  <div className="brand-container">
                    <span className="brand-title">Bloomberg Tax</span>
                    <div className="divider"></div>
                    <span className="brand-product-title">Workpapers</span>
                    <span className="visible-xs-inline">Applications</span>
                  </div>
                </Link>
              </li>
              {userInfo?.companyNameOverride && (
                <div className="navbar-header">
                  <button
                    className="navbar-toggle collapsed"
                    data-target="#main-navbar-collapse"
                    data-toggle="collapse"
                    type="button"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <span className="navbar-button-text">{userInfo.companyNameOverride}</span>
                </div>
              )}
              {pathName !== CLIENT_SELECTION_PATH && <ClientPickerMenu />}
            </ul>
            <div className="collapse navbar-collapse" id="main-navbar-collapse">
              <ul className="nav navbar-nav navbar-right">
                {pathName === DATA_TRANSFORMATION_PATH && (
                  <li>
                    <a id="onboarding-btn">
                      <BTIcon icon="compass" />
                      <span className="navbar-button-text">Onboarding</span>
                    </a>
                  </li>
                )}

                {isFeatureFlagEnabled(FEEDBACK_FORM) && (
                  <li>
                    <a id="feedback-btn" onClick={onOpenFeedbackFormClick}>
                      <img alt="Feedback Form Icon" className="navbar-button-icon" src={feedFormbackIcon} />
                      <span className="navbar-button-text">Feedback</span>
                    </a>
                  </li>
                )}
                {isFeatureFlagEnabled(RESEARCH_AI) && <TaxResearchAi />}

                <CmAdminMenu />

                <li className="dropdown help">
                  <a
                    className="dropdown-toggle"
                    data-testid="help-dropdown"
                    data-toggle="dropdown"
                    href="#"
                    id="header-help-button"
                    title="Help"
                  >
                    <BTIcon icon="question-circle" />
                    <span className="visible-xs-inline">Help</span>
                  </a>
                  <ul aria-labelledby="header-help-button" className="dropdown-menu" role="menu">
                    <li className="" role="presentation">
                      <a
                        data-testid="help-button"
                        href="/help/index.html"
                        id="help-center"
                        role="menuitem"
                        target="_blank"
                        title="Help"
                        onClick={() => {
                          trackEvent('help-documentation', itemTypes.BUTTON);
                        }}
                      >
                        Help center
                      </a>
                    </li>
                    <li className="" data-testid="release-notes-button" role="presentation">
                      <a id="help-release-notes" role="menuitem" onClick={() => {}}>
                        Release notes
                      </a>
                    </li>
                    <li className="" data-testid="give-feedback-button" role="presentation">
                      <a id="help-give-feedback" role="menuitem" onClick={() => {}}>
                        Give feedback
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown user-profile">
                  <a
                    className="dropdown-toggle"
                    data-testId="cm-user-dropdown"
                    data-toggle="dropdown"
                    href="#"
                    id="userMenu"
                  >
                    <BTIcon icon="user" />
                    <span className="visible-xs-inline">User Preferences</span>
                  </a>
                  <ul aria-labelledby="userMenu" className="dropdown-menu" role="menu">
                    <li aria-level="2" className="dropdown-header" role="heading">
                      {userInfoDisplay}
                    </li>
                    <li className="" role="presentation">
                      <a data-testid="cm-logout-user" role="menuitem" onClick={onLogoutConfirm}>
                        <div className="logout-item">
                          <BTIcon icon="logout" />
                          <span>Log Out</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <DataFlowSaveChangesModal showConfirm={showConfirm} onCloseConfirm={onCloseConfirm} onConfirm={onClickConfirm} />
    </>
  );
};

export default CmFakeBanner;
