import React from 'react';
import { fixedAssetsLoginPageUrl } from '../../../../configs/params';
import CustomIcon from './CustomIcon/CustomIcon';

export const applications = [
  {
    link: `${fixedAssetsLoginPageUrl}/fa`,
    name: 'Fixed Assets',
    icon: <CustomIcon name="fixed-assets" />,
    testId: 'app-picker-fa',
  },
  {
    link: 'https://provision.bloombergtax.com/',
    name: 'Provision',
    icon: <CustomIcon name="provision" />,
    testId: 'app-picker-provision',
  },
  {
    link: 'https://bloombergtax.com/',
    name: 'Research',
    icon: <CustomIcon name="research" />,
    testId: 'app-picker-research',
  },
  {
    link: '/data-flows',
    name: 'Workpapers',
    icon: <CustomIcon name="workpapers" />,
    testId: 'app-picker-workpapers',
  },
];
