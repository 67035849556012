/**
 * This hook handles logic around reference tags
 */
export function useCellTrackerTags({ dataReferenceCellTagUpdateQueue }) {
  function enqueueCellTagUpdateQueue(updateCellReferencesTags, referencesToUpdate = []) {
    updateCellReferencesTags = [...updateCellReferencesTags, ...referencesToUpdate];
    if (updateCellReferencesTags.length) {
      const uniqueResetTags = [...dataReferenceCellTagUpdateQueue.current, ...updateCellReferencesTags];

      dataReferenceCellTagUpdateQueue.current = uniqueResetTags.filter((uniqueResetTag, index) => {
        return (
          index ===
          uniqueResetTags.findIndex(
            x =>
              uniqueResetTag.row === x.row &&
              uniqueResetTag.column === x.column &&
              uniqueResetTag.sheetName === x.sheetName
          )
        );
      });

      updateCellReferencesTags = [];
    }
  }

  return {
    enqueueCellTagUpdateQueue,
  };
}
