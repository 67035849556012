import { TooltipToggleHelper } from './tooltipToggleHelper';
import { transformIntoString } from '../_spreadsheets/stateTaxRateFormulaHelper';
import { STATE_TAX_RATE } from '../../../../_shared/DataReference/ReferenceType';
import { tooltipTogglerCacheTimeout } from '../../../../../configs/params';
import { resolveFormulaValues } from '../../DataReference/apis';

const cache = {};

const getCacheKey = args => JSON.stringify(args);

export class StateTaxTooltipToggleHelper extends TooltipToggleHelper {
  /**
   * Factory function to create TooltipHelper instances based on formula type.
   * @param {any} fnArguments
   * @param {string} workpaperId
   * @param {any[]} datareferences
   * @param {{row: Number; col: Numeber; sheetName: string}} cell
   * @param {() => Promise<void>} onNotFound
   * @returns {TooltipToggleHelper | undefined}
   */
  constructor(fnArguments, workpaperId, dataReferences, { row, col, sheetName }, onNotFound) {
    super();
    this.args = fnArguments;
    const [jurisdiction, , taxableIncome] = fnArguments;
    const periodStartDate = transformIntoString(fnArguments[1]);

    this.workpaperId = workpaperId;
    this.row = row;
    this.col = col;
    this.sheetName = sheetName;
    this.dataReferences = dataReferences;
    this.onNotFound = onNotFound;
    this.jurisdiction = jurisdiction;
    this.periodStartDate = periodStartDate;
    this.taxableIncome = taxableIncome;
    this.type = STATE_TAX_RATE;
  }

  _parseTooltipContent({ customRateSummary, calculationType, citations }) {
    let tooltipContent = customRateSummary;

    if (calculationType !== 'no_tax') {
      tooltipContent += `BOLD${citations}BOLD`;
    }

    return tooltipContent;
  }

  _parseFutureDate(dateLocalString) {
    return dateLocalString.split(',')[0];
  }

  _parseDisclaimer({ jurisdiction, futureTaxRate }) {
    if (!futureTaxRate) {
      return null;
    }

    const effectiveDate = this._parseFutureDate(futureTaxRate.startDate);
    return `The corporate income tax rate for ${jurisdiction} will change to ${futureTaxRate.taxChange}, effective ${effectiveDate}`;
  }

  _findStateTaxrateDataReference() {
    return this.dataReferences.current
      .filter(dr => dr.type === 1)
      .find(({ row, column, sheetName }) => row === this.row && column === this.col && this.sheetName === sheetName);
  }

  async resolveDataReference() {
    const response = await resolveFormulaValues(this.workpaperId, [
      {
        key: JSON.stringify({ row: this.row, column: this.col, type: 1, sheetName: this.sheetName }),
        jurisdiction: this.jurisdiction,
        income: this.taxableIncome,
        startDate: this.periodStartDate,
      },
    ]);
    const resolves = await response.json();
    if (!resolves || resolves.lenght < 1) {
      return null;
    }

    return resolves[0];
  }

  _dataReferenceMatch(dataReference) {
    if (!dataReference) return false;

    let { parameters } = dataReference;
    parameters = JSON.parse(parameters);
    return (
      parameters.Jurisdiction === this.jurisdiction &&
      parameters.PeriodStartDate === this.periodStartDate &&
      ((!this.taxableIncome && !parameters.TaxableIncome) || parameters.TaxableIncome === this.taxableIncome)
    );
  }

  async _fetchData() {
    try {
      let dataReference = this._findStateTaxrateDataReference();
      if (!dataReference?.extraData || !dataReference?.extraData?.tooltip) {
        await this.onNotFound();
        dataReference = this._findStateTaxrateDataReference();
      }
      if (!dataReference?.extraData || !dataReference?.extraData?.tooltip || !this._dataReferenceMatch(dataReference)) {
        dataReference = await this.resolveDataReference();
      }

      const {
        extraData: { tooltip },
      } = dataReference;
      const link = tooltip?.link;
      const tooltipContent = this._parseTooltipContent(tooltip);
      const upcomingRateMsg = this._parseDisclaimer(tooltip);

      return { data: { tooltipContent, link }, upcomingRateMsg };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async fetchData() {
    const cacheKey = getCacheKey(this.args);
    const cachedEntry = cache[cacheKey];

    if (cachedEntry && Date.now() - cachedEntry.timestamp < tooltipTogglerCacheTimeout) {
      return cachedEntry;
    }

    if (this.jurisdiction && this.periodStartDate) {
      try {
        const data = await this._fetchData();
        cache[cacheKey] = { ...data, timestamp: Date.now() };

        return data;
      } catch {
        return { errorMessage: 'Error getting state tax rate.' };
      }
    }
  }
}
