import { LogLevel, faro } from '@grafana/faro-web-sdk';
import { getUser } from '../auth';
import { EnviromentName } from '../../../configs/params';
import { consoleLogging } from '../../../configs/params';
export default class CustomLogger {
  static operations = {
    SPREADSHEETS_LANDING_PAGE: 'spreadsheets-landing-page',
    DATACONNECT_LANDING_PAGE: 'dataconnect-landing-page',
    UPLOAD: 'upload-workpaper',
    OPEN: 'open-workpaper',
    DELETE: 'delete-workpaper',
    EXPORT: 'export-workpaper',
    COPY: {
      WORKPAPER: 'copy-workpaper',
      SHEET: 'copy-worksheet',
    },
    PROCESS_COMMAND: 'process-command',
    NAVIGATE: 'navigate',
    STATIC_VERSION_GENERATION: 'static-version-generation',
    RESTORE: 'restore-workpaper',
    BLOCK_TO_PREVIEW: 'block-to-preview',
    DELETE_WORKFLOW: 'delete_workflow',
    RETRIEVE_USER_PERMISSIONS: 'retrieve-user-permissions',
    GENERAL: 'general',
    RECOVER: 'recover-workpaper',
    GETTING_ROLE: 'getting-role',
    LOAD_PREVIEW: 'load-preview',
    JOIN_BLOCK: 'join-block',
    GENERATE_AI_EXPRESSION: 'generate-ai-expression',
    CONVERT_CSV_TO_XLSX: 'convert-csv-to-xlsx',
    RETRY_OPEN: 'retry-open-workpaper',
    SEND_TO_TP: 'send-to-tp',
    SEND_TO_FA: 'send-to-fa',
    SEND_TO_SFTP: 'send-to-sftp',
    SFTP_INTEGRATION: 'sftp-integration',
    DOWNLOAD_OUTPUT: 'download-output',
    LOCK_WORKFLOW: 'lock-workflow',
    LOAD_WORKFLOW_FROM_API: 'load-workflow-from-api',
    SEND_TO_AMPLITUDE: 'send-to-amplitude',
    JSON_SYNC: 'json-sync',
    REFRESH_TOKEN: 'refresh-token',
    SAVE_AND_PUBLISH: 'save_and_publish_workflow',
    SAVE_AS: 'save_as',
    INGEST_FILE: {
      GENERAL: 'common_ingestion_operations',
      UPLOAD: 'upload-file-to-data-connection',
      FROM_FA: 'ingest-file-from-fixed-assets',
      FROM_SFTP: 'ingest-file-from-sftp',
    },
    CREATE: {
      WORKFLOW: 'create-workflow',
    },
    SAVE: {
      WORKFLOW: 'save-workflow',
    },
    PNG_AUDIT_SNAPSHOT_UPLOAD: 'png-audit-snapshot-upload',
    DATA_CONNECT_PREVIEW: 'data-connect-preview',
    POLLING_JOB: 'polling-job',
  };

  static durations = Object.create(null);

  static start(log) {
    CustomLogger.durations[log] = {
      duration: Date.now(),
    };
  }

  static addDurationPayload(log, payload = {}) {
    if (CustomLogger.durations[log]) {
      CustomLogger.durations[log] = {
        ...CustomLogger.durations[log],
        ...payload,
      };
    }
  }

  static end(log) {
    if (!CustomLogger.durations[log]) {
      return;
    }

    const user = getUser();

    faro?.api?.pushLog([log], {
      context: {
        ...CustomLogger.durations[log],
        userId: user?.userId,
        companyId: user?.companyId,
        duration: `${Date.now() - CustomLogger.durations[log].duration}`,
      },
      level: LogLevel.DEBUG,
    });

    delete CustomLogger.durations[log];
  }

  /**
   *
   * @param {string} log Log operation
   * @param {Object} payload Payload that will be sent to the log. This must be an object.
   */
  static pushLog(log, payload) {
    const user = getUser();

    if (consoleLogging === 'enabled' || (EnviromentName && EnviromentName === 'LOCAL')) {
      if (typeof payload === 'string') {
        console.log(payload);
      } else {
        console.log(JSON.stringify(payload));
      }
    }

    faro?.api?.pushLog([log], {
      context: {
        userId: user?.userId,
        companyId: user?.companyId,
        ...payload,
      },
      level: payload?.error ? LogLevel.ERROR : LogLevel.DEBUG,
    });
  }
}
