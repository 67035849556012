import React, { useState } from 'react';
import { BTModal, BTButton } from '@btas/jasper';
import './WorkpaperSendToTPModal/styles.scss';
import sendToTPScreenShot from './WorkpaperSendToTPModal/TP_Export.png';
import {
  copyFiles,
  createNodeObject,
  existedNode,
  exportToTPClassic,
  getPresignedUrl,
  updateNodeObject,
} from './WorkpaperSendToTPModal/apis';
import { TAX_PROVISION_CLASSIC_URL, TAX_PROVISION_URL } from './shared/constants';
import GeneralErrorMessage from '../../../_shared/GeneralErrorMessage';
import { getClientId, getUser, getUserInfo } from '../../../_shared/auth';
import { EnviromentUrl } from '../../../../configs/params';
import { ResourceType, IntegrationType } from './WorkpaperSendToTPModal/constants';
import { v4 as uuidv4 } from 'uuid';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { WKP_CM_INTEGRATION, WKP_TP_CLASSIC_S3_ORGANIZATION } from '../../../../constants/featureFlags';
import { eventTrackTagNames } from '../../../_shared/EventTrackingContext/constants';
import { processWorkbookForExport } from './shared/utils';

export default function WorkpaperSendToTPModal({
  show,
  sourceWorkpaperId,
  onClose,
  showSuccessModal,
  sourceTaxPeriod,
  sourceWorkpaperName,
}) {
  const [fetchError, setFetchError] = useState(null);
  const [fetching, setFetching] = useState(false);

  const userInfo = getUser();
  const hasProvSubscription = userInfo?.subscriptions && userInfo.subscriptions.includes('PROV');

  const generateClassicKey = (sourceWorkpaperId, fileName) => {
    const userInfo = getUser();
    const { companyId = null } = userInfo != null && (typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo);

    return `${companyId}/wkp-output-files/spreadsheet/${sourceWorkpaperId}/${fileName}`;
  };

  const openTaxProvision = () => {
    const hasProvSubscription = userInfo?.subscriptions && userInfo.subscriptions.includes('PROV');
    window.open(hasProvSubscription ? TAX_PROVISION_URL : TAX_PROVISION_CLASSIC_URL, '_blank');
  };

  const sendToTaxProvisionV2 = async (sourceWorkpaperId, sourceWorkpaperName, sourceTaxPeriod, fileName, location) => {
    const downloadPresignedResult = await getPresignedUrl(fileName, location);
    const downloadPresignedUrl = downloadPresignedResult.presignedUrls.find(output => output[fileName])?.[fileName];

    const fileData = {
      name: fileName,
      location: location,
      presignedUrl: downloadPresignedUrl,
      destination: ['tax-provision.bna.com'],
      fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    const objectPayload = {
      resourceId: sourceWorkpaperId,
      name: sourceWorkpaperName,
      resourceUrl: `${EnviromentUrl}/editor/${sourceWorkpaperId}`,
      resourceTaxPeriod: sourceTaxPeriod,
      files: [fileData],
      resourceType: ResourceType.Spreadsheet,
      canSendEvent: hasProvSubscription,
      integrationType: IntegrationType.TaxProvision,
    };

    const nodeExisted = await existedNode(sourceWorkpaperId);

    if (nodeExisted?.existed) {
      await updateNodeObject(objectPayload);
    } else {
      await createNodeObject(objectPayload);
    }
  };

  const sendToTaxProvisionClassic = async (sourceWorkpaperId, sourceTaxPeriod, location, fileName) => {
    const userInfo = await getUserInfo();
    const email = userInfo?.designatedUser?.email || userInfo?.sharedUser?.email;

    // generate url for TP 1.0
    const tpClassicLocation = generateClassicKey(sourceWorkpaperId, fileName);
    const copyPayload = {
      sourceBucketType: 'Integration',
      sourcePath: location,
      destinationBucketType: 'Data',
      destinationPath: tpClassicLocation,
      componentType: ResourceType.Spreadsheet,
      resourceId: sourceWorkpaperId,
    };

    if (isFeatureFlagEnabled(WKP_TP_CLASSIC_S3_ORGANIZATION)) {
      const currentDate = new Date();
      const currentDateUTC = currentDate.toISOString();
      const metadata = {
        processId: uuidv4(),
        componentType: ResourceType.Spreadsheet,
        resourceId: sourceWorkpaperId,
        resourceUrl: `${EnviromentUrl}/editor/${sourceWorkpaperId}`,
        resourceName: sourceWorkpaperName,
        resourceTaxPeriod: sourceTaxPeriod,
        sendTime: currentDateUTC,
      };

      copyPayload.metadata = metadata;
    }

    // copy file between buckets
    await copyFiles(copyPayload);

    // call lambda to tax provision
    const sendToTPClassicPayload = {
      email: email,
      filePath: tpClassicLocation,
      resourceId: sourceWorkpaperId,
      ...(isFeatureFlagEnabled(WKP_TP_CLASSIC_S3_ORGANIZATION) && {
        resourceName: sourceWorkpaperName,
        componentType: ResourceType.Spreadsheet,
        taxPeriod: sourceTaxPeriod,
      }),
      ...(isFeatureFlagEnabled(WKP_CM_INTEGRATION) && {
        clientId: getClientId(),
      }),
    };
    await exportToTPClassic(sendToTPClassicPayload);
  };

  const proceedData = async () => {
    setFetchError(null);
    setFetching(true);
    try {
      const { location, fileName } = await processWorkbookForExport(sourceWorkpaperId, IntegrationType.TaxProvision);

      if (hasProvSubscription) {
        await sendToTaxProvisionV2(sourceWorkpaperId, sourceWorkpaperName, sourceTaxPeriod, fileName, location);
      }

      await sendToTaxProvisionClassic(sourceWorkpaperId, sourceTaxPeriod, location, fileName);

      setFetching(false);
      onClose();
      showSuccessModal();
    } catch (err) {
      setFetching(false);
      setFetchError(err);
    }
  };
  return (
    <BTModal
      className="workpaper-send-to-tp-modal"
      id="workpaper-send-to-tax-provision-modal"
      show={show}
      size="modal-lg"
      title="Before You Send to Bloomberg Tax Provision"
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <p>
          Before you send, ensure this sheet is formatted for an <b>Amounts Upload</b>. If your data is not formatted,
          you can create a template by performing an <b>Export</b> from the Tax Provision application and selecting{' '}
          <b>Amounts</b> as the data to export.
        </p>
        <div>
          <img alt="send to tax provision screenshot" className="tpscreenshot" src={sendToTPScreenShot} />
        </div>
        {fetchError && <GeneralErrorMessage dismissible={false} />}
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton id="cancel" onClick={onClose}>
          Cancel
        </BTButton>
        <BTButton id="open-tax-provision" onClick={openTaxProvision}>
          OPEN TAX PROVISION
        </BTButton>
        <BTButton
          btStyle="primary"
          data-track-tag={eventTrackTagNames.interactive}
          disabled={fetching}
          hasSpinner={fetching}
          id="sendTp"
          onClick={proceedData}
        >
          PROCEED WITH SEND
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}
