/* eslint-disable eqeqeq */
/**
 * This file overrides the paint method, keeping the older paint methods hierarchy untouched.
 *
 * The changes here are only related to Cell Review.
 */

import GC from '../../../../../../SpreadSheets';
import { isCellOnScreenValidator } from './cellReview/utils';
import { registerTooltip, unregisterTooltip } from '../tooltipHelper';
import { CELL_REVIEW } from '../../../../../_shared/DataReference/ReferenceType';
import { cleanDirtyCellValue } from '../../../DataReference/dataReferenceHelper';
import { customFunctionNames, datalinkFormulaRegex } from '../formulas';

const moment = require('moment');
function getCellReviewsInTargetCell(row, column, sheetName, dataReferences) {
  return dataReferences?.current?.filter(
    x => x.row === row && x.column === column && x.sheetName === sheetName && x.type === CELL_REVIEW
  );
}

const greenCircle = '#00A223';
const redCircle = '#C31834';
const taxFormulaCornerFold = '#0073FF';
const iconStyle = `
display: inline-flex;
width: 14px;
height: 14px;
align-items: center;
justify-content: center;
border-radius: 50%;
position: relative;
margin-right: 4px;
`;

const checkmarkStyle = `
width: 4px;
height: 10px;
border: solid white;
border-width: 0 2px 2px 0;
transform: rotate(45deg);
`;

export function dataReferenceOverridePaint(tooltipManager, dataReferences) {
  // Applying these to avoid overriding spreadJS default and other custom functionality
  const prevPaint = GC.Spread.Sheets.CellTypes.Base.prototype.paint;
  GC.Spread.Sheets.CellTypes.Base.prototype.paint = function (ctx, _, x, y, w, h, style, context) {
    prevPaint.apply(this, arguments);

    const { row, col, sheet } = context;

    const cellReviews = getCellReviewsInTargetCell(row, col, sheet.name(), dataReferences);

    if (cellReviews?.length) {
      const cell = sheet.getCell(row, col);
      const cellValue = cell.text() || '';

      const reviewParameters = cellReviews.map(review => JSON.parse(review.parameters));
      const isReviewValid = reviewParameters.every(({ ApprovedValue }) => {
        return validateCellReview(cellValue, ApprovedValue);
      });

      // --- Draw the circular icon (Cell Review) ---
      const circleRadius = 7;
      const circleX = x + circleRadius + 5;
      const circleY = y + circleRadius + 5;
      ctx.fillStyle = isReviewValid ? greenCircle : redCircle;
      ctx.beginPath();
      ctx.arc(circleX, circleY, circleRadius, 0, 2 * Math.PI);
      ctx.fill();

      // Draw the white checkmark inside the circle
      if (isReviewValid) {
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(circleX - circleRadius * 0.5, circleY);
        ctx.lineTo(circleX - circleRadius * 0.2, circleY + circleRadius * 0.5);
        ctx.lineTo(circleX + circleRadius * 0.6, circleY - circleRadius * 0.3);
        ctx.stroke();
      } else {
        // Draw exclamation mark inside the circle
        ctx.font = `${circleRadius * 2}px Arial`;
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('!', circleX, circleY);
      }
      style.textIndent = 2.5;
    }

    const formula = sheet.getFormula(row, col);
    if (customFunctionNames.some(name => formula?.includes(name) || datalinkFormulaRegex.test(formula))) {
      // --- Draw the corner fold ---
      const cornerFoldSize = 9;
      ctx.fillStyle = taxFormulaCornerFold;
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x + cornerFoldSize, y);
      ctx.lineTo(x, y + cornerFoldSize);
      ctx.closePath();
      ctx.fill();
    }
  };

  GC.Spread.Sheets.CellTypes.Base.prototype.getHitInfo = function (x, y, cellStyle, cellRect, context) {
    const { row, col } = context;
    return cellRect?.x === 0 || cellRect?.y === 0
      ? null
      : {
          x,
          y,
          row,
          col,
          cellStyle,
          cellRect: cellRect,
          sheetArea: context.sheetArea,
          context,
        };
  };

  GC.Spread.Sheets.CellTypes.Base.prototype.processMouseEnter = function (hitInfo) {
    if (!hitInfo || !hitInfo?.cellRect?.x || !hitInfo?.cellRect?.y) {
      return;
    }
    const { row, col, cellRect } = hitInfo;
    const { x, y } = cellRect;
    const sheet = hitInfo.sheet;

    const cellReviews = getCellReviewsInTargetCell(row, col, sheet.name(), dataReferences);
    // clean last drawing and tooltip in case the cell has been un-reviewed
    const tooltipId = `wkp_cell_review_row_${row}_col_${col}`;
    const unregister = () => unregisterTooltip(tooltipManager, tooltipId);
    unregister();

    if (cellReviews?.length) {
      const reviewParameters = cellReviews.map(review => JSON.parse(review.parameters));
      const cell = sheet.getCell(row, col);
      const cellValue = cell.text() || '';

      // the tooltip is registered
      let tooltipContent = '';

      reviewParameters.forEach(parameters => {
        tooltipContent += getTooltipHTML(parameters, cellValue);
      });

      // add margin styles
      tooltipContent = `<div style="margin-top: 10px; margin-right: 5px; margin-bottom: 10px; white-space: normal; text-align: left; font-family: sans-serif;">${tooltipContent}</div>`;

      registerTooltip(
        tooltipManager,
        x,
        y,
        { height: 16, width: 16 },
        { x: 16 / 2 + 2, y: 5 },
        tooltipId,
        tooltipContent,
        true,
        isCellOnScreenValidator({ GC, sheet, row, col, onNotActive: unregister })
      );
    }
  };

  GC.Spread.Sheets.CellTypes.Base.prototype.processMouseLeave = function (hitInfo) {
    const { row, col } = hitInfo;
    const tooltipId = `wkp_cell_review_row_${row}_col_${col}`;
    unregisterTooltip(tooltipManager, tooltipId);
    return true;
  };

  function getTooltipHTML(params, cellValue) {
    let { ApprovedValue, ReviewDateTime, ReviewerUserFirstName } = params;
    const isValid = validateCellReview(cellValue, ApprovedValue);
    const sanitizedReviewerUserFirstName = sanitizeHTML(ReviewerUserFirstName);

    // Only add timezone if needed
    ReviewDateTime = ReviewDateTime.endsWith('Z') ? ReviewDateTime : ReviewDateTime + 'Z';

    const formattedDate = moment(ReviewDateTime).format('MM/DD/YYYY, h:mm A');

    let reviewEntry = `
    <div style="display: flex; white-space: normal;">
      <div data-testid="${isValid ? 'valid' : 'invalid'}-${sanitizedReviewerUserFirstName}" style="${iconStyle} background-color: ${isValid ? greenCircle : redCircle};">
      ${isValid ? `<div style="${checkmarkStyle}"></div>` : `<span style="color: white; font-size: 12px; font-weight: bold; line-height: 1;">!</span>`}
      </div>
      <span style="margin-top: 1px;">${sanitizedReviewerUserFirstName} | ${formattedDate}</span>
    </div>`;

    if (!isValid) {
      reviewEntry += `<div style="margin-left: 20px;">Reviewed previous value of '${ApprovedValue}'</div>`;
    }
    return reviewEntry;
  }

  function validateCellReview(cellValue, approvedValue) {
    // Normalize values
    cellValue = formatReviewCellValue(cellValue);
    approvedValue = formatReviewCellValue(approvedValue);

    // Number cases
    const numericApprovedValue = parseFloat(approvedValue);

    if ((isNum(numericApprovedValue) && numericApprovedValue % 1 !== 0) || numericApprovedValue == 1) {
      if (isNum(cellValue)) {
        return parseFloat(cellValue) === numericApprovedValue;
      }
    }

    // String cases
    return cellValue == approvedValue;
  }

  function formatReviewCellValue(value) {
    if (typeof value == 'string' && value?.endsWith('%')) {
      return parseFloat(value) / 100;
    }

    return cleanDirtyCellValue(value);
  }

  const isNum = value => !isNaN(value);

  function sanitizeHTML(str) {
    return str.replace(/[&<>"']/g, function (match) {
      return {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
      }[match];
    });
  }
}
