import { CommandType } from '../../../_shared/commandType';

/**
 * This hook handles column insert/delete logic.
 */
export function useCellTrackerColumn() {
  function handleColumnOperations(
    cmd,
    isUndo,
    colCount,
    row,
    col,
    referencesToUpdate,
    updateDataReferenceCellPositions
  ) {
    let columnPositionCount = colCount;
    let colFilter = 'column';
    let colType = 'column';
    let colOperation = 'greater-equal';

    if (
      cmd === CommandType.DeleteEntireColumn ||
      cmd === CommandType.DeleteCellsLeft ||
      cmd === CommandType.DeleteEntireRowColumn
    ) {
      columnPositionCount = -columnPositionCount;
    }

    if (cmd === CommandType.DeleteCellsLeft || cmd === CommandType.InsertCellsRight) {
      colFilter = 'row';
      colOperation = 'equal';
    }

    if (isUndo) {
      columnPositionCount = -columnPositionCount;
      if (colOperation === 'equal') {
        colOperation = 'greater-equal';
      }
    }

    return updateDataReferenceCellPositions(
      referencesToUpdate,
      colType,
      colFilter,
      colOperation,
      row,
      col,
      columnPositionCount
    );
  }

  return {
    handleColumnOperations,
  };
}
