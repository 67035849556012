import { isEqual, omit } from 'lodash';
import { ElementsActions } from './elementsReducer';
import { LinksActions } from './linksReducer';
import { InfoActions } from './infoReducer';

export const SAVE_STATE = {
  dirty: 'save menu all enable',
  save: 'save menu disable save only',
  published: 'save menu all disabled',
};

export const initialState = {
  isDirty: false,
  isFailedToRestore: false,
  isDraggingElementFromCanvas: false,
  isDraggingElementFromStencil: false,
  lastModifiedDate: null,
  lastPublishedDate: null,
  isValid: false,
  saveMenu: { saveButton: true, saveAndPubButton: true, exportButton: false, state: SAVE_STATE.published },
  validationErrors: null,
  isClickingCanvas: false,
};

export const SaveStateActions = {
  setDirty: 'set-dirty',
  setFailedToRestore: 'set-failed-to-restore',
  setDraggingElementFromCanvas: 'set-dragging-element-from-canvas',
  setDraggingElementFromStencil: 'set-dragging-element-from-stencil',
  setLastModifiedDate: 'set-last-modified-date',
  setLastPublishedDate: 'set-last-published-date',
  setIsValid: 'set-valid-result',
  setIsHeaderInfoShown: 'set-header-info-shown',
  setValidationErrors: 'set-validation-errors',
  setSaveMenuSave: 'set-save-menu-save',
  setSaveMenuSaveAndPublish: 'set-save-menu-save-and-publish',
  resetSaveMenuDirty: 'reset-save-menu-dirty',
  setClickingCanvas: 'set-clicking-canvas',
  setPreviewOutputNode: 'set-preview-output-node',
  clearPreviewOutputNode: 'clear-preview-output-node',
};

export const saveStateActions = dispatch => ({
  setSaveStateDirty: isDirty => dispatch({ type: SaveStateActions.setDirty, isDirty }),
  setFailedToRestoreState: isFailedToRestore =>
    dispatch({
      type: SaveStateActions.setFailedToRestore,
      isFailedToRestore,
    }),
  setValidationErrors: validationErrors =>
    dispatch({
      type: SaveStateActions.setValidationErrors,
      validationErrors,
    }),
  setDraggingElementFromCanvas: isDraggingElementFromCanvas =>
    dispatch({ type: SaveStateActions.setDraggingElementFromCanvas, isDraggingElementFromCanvas }),
  setDraggingElementFromStencil: isDraggingElementFromStencil =>
    dispatch({ type: SaveStateActions.setDraggingElementFromStencil, isDraggingElementFromStencil }),
  setLastModifiedDate: lastModifiedDate => dispatch({ type: SaveStateActions.setLastModifiedDate, lastModifiedDate }),
  setLastPublishedDate: lastPublishedDate =>
    dispatch({ type: SaveStateActions.setLastPublishedDate, lastPublishedDate }),
  setIsValid: isValid => dispatch({ type: SaveStateActions.setIsValid, isValid }),
  setIsHeaderInfoShown: isHeaderInfoShown =>
    dispatch({ type: SaveStateActions.setIsHeaderInfoShown, isHeaderInfoShown }),
  setSaveMenuSave: () => dispatch({ type: SaveStateActions.setSaveMenuSave }),
  setSaveMenuSaveAndPublish: () => dispatch({ type: SaveStateActions.setSaveMenuSaveAndPublish }),
  resetSaveMenuDirty: () => dispatch({ type: SaveStateActions.resetSaveMenuDirty }),
  setClickingCanvas: isClickingCanvas => dispatch({ type: SaveStateActions.setClickingCanvas, isClickingCanvas }),
  setPreviewOutputNode: previewOutputNode =>
    dispatch({ type: SaveStateActions.setPreviewOutputNode, previewOutputNode }),
  clearPreviewOutputNode: () => dispatch({ type: SaveStateActions.clearPreviewOutputNode }),
});

export const saveStateReducer = (state, action) => {
  switch (action.type) {
    case ElementsActions.addElement:
    case ElementsActions.removeElement:
    case LinksActions.addLink:
    case LinksActions.removeLink:
    case InfoActions.setName: {
      return action.restore ? state : { ...state, isDirty: true };
    }

    case ElementsActions.setElementData: {
      const isDirty = state.isDirty || isElementChanged(state, action.elementId, action.elementData);
      return { ...state, isDirty };
    }
    case ElementsActions.updateWorkingElement: {
      const { workingElement } = state;

      const isDirty =
        state.isDirty ||
        (workingElement && isElementChanged(state, workingElement.id, workingElement.elementData, true));
      return { ...state, isDirty };
    }

    case SaveStateActions.setDirty: {
      return { ...state, isDirty: action.isDirty };
    }

    case SaveStateActions.setIsValid: {
      return { ...state, isValid: action.isValid };
    }

    case SaveStateActions.setIsHeaderInfoShown: {
      return { ...state, isHeaderInfoShown: action.isHeaderInfoShown };
    }

    case SaveStateActions.setLastModifiedDate: {
      return { ...state, lastModifiedDate: action.lastModifiedDate };
    }
    case SaveStateActions.setLastPublishedDate: {
      return { ...state, lastPublishedDate: action.lastPublishedDate };
    }
    case SaveStateActions.setFailedToRestore: {
      return { ...state, isFailedToRestore: action.isFailedToRestore };
    }
    case SaveStateActions.setDraggingElementFromCanvas: {
      return { ...state, isDraggingElementFromCanvas: action.isDraggingElementFromCanvas };
    }
    case SaveStateActions.setDraggingElementFromStencil: {
      return { ...state, isDraggingElementFromStencil: action.isDraggingElementFromStencil };
    }
    case SaveStateActions.resetSaveMenuDirty: {
      if (state.saveMenu.state !== SAVE_STATE.dirty) {
        return {
          ...state,
          saveMenu: { saveButton: false, saveAndPubButton: false, exportButton: true, state: SAVE_STATE.dirty },
        };
      } else {
        return state;
      }
    }
    case SaveStateActions.setSaveMenuSave: {
      if (state.saveMenu.state !== SAVE_STATE.save) {
        return {
          ...state,
          saveMenu: { saveButton: true, saveAndPubButton: false, exportButton: true, state: SAVE_STATE.save },
        };
      } else {
        return state;
      }
    }
    case SaveStateActions.setSaveMenuSaveAndPublish: {
      if (state.saveMenu.state !== SAVE_STATE.published) {
        return {
          ...state,
          saveMenu: { saveButton: true, saveAndPubButton: true, exportButton: false, state: SAVE_STATE.published },
        };
      } else {
        return state;
      }
    }
    case SaveStateActions.setValidationErrors: {
      return { ...state, validationErrors: action.validationErrors };
    }
    case SaveStateActions.setClickingCanvas: {
      return { ...state, isClickingCanvas: action.isClickingCanvas, previewOutputNode: null };
    }
    case SaveStateActions.setPreviewOutputNode: {
      return { ...state, previewOutputNode: action.previewOutputNode };
    }
    case SaveStateActions.clearPreviewOutputNode: {
      return { ...state };
    }
    default:
      return state;
  }
};

function isElementChanged(state, elementId, elementData, omitIntegrationType = false) {
  const currentElement = state.elements[elementId];
  if (!currentElement) {
    return true;
  }

  const newElementData = { ...currentElement.elementData, ...elementData };
  //integrationType is cleaned when we click on input block
  //so we leave that property out of the comparision to avoid wrong if omitIntegrationType is received
  if (omitIntegrationType) {
    return !isEqual(omit(newElementData, ['integrationType']), omit(currentElement.elementData, ['integrationType']));
  }

  return !isEqual(newElementData, currentElement.elementData);
}
