import { CELL_REVIEW, DATA_LINK } from '../../../_shared/DataReference/ReferenceType';
import { CommandType } from '../../../_shared/commandType';
import { cleanDirtyCellValue } from './dataReferenceHelper';
import { BroadcastChannels, EventTypes } from '../useBroadcast/constants';
import { useCellTrackerPaste } from './useCellTrackerPaste';
import { useCellTrackerRow } from './useCellTrackerRow';
import { useCellTrackerTags } from './useCellTrackerTags';
import { useCellTrackerDelete } from './useCellTrackerDelete';
import { useCellTrackerColumn } from './useCellTrackerColumn';
import { useCellTrackerUpdate } from './useCellTrackerUpdate';

export default function useDataReferenceCellTracker(
  spreadRef,
  workpaperIdRef,
  dataReferences,
  dataReferencePositionUpdateQueue,
  dataReferenceDeleteQueue,
  dataReferenceCellTagUpdateQueue,
  lastCellReviewActionTimestamp,
  deleteCellReviewCommand = null,
  dataReferenceHistoryTracker,
  processDataReferencePositionQueue,
  processDataReferencesDeleteQueue,
  enqueueManyDataReferences,
  processCellReviewQueue,
  sendMessage
) {
  const { addToHistory } = dataReferenceHistoryTracker?.current;

  let updateCellReferencesTags = [];

  const formulas = ['SOURCE_DATA', 'INTTAXRATE', 'STATETAXRATE', 'STATEAPPORTION'];

  /**
   * =====================
   *      Child Hooks
   * =====================
   */
  const { handleRowOperations } = useCellTrackerRow();

  const { handleColumnOperations } = useCellTrackerColumn();

  const { enqueueCellTagUpdateQueue } = useCellTrackerTags({ dataReferenceCellTagUpdateQueue });

  const { deleteDataReferences } = useCellTrackerDelete({
    dataReferences,
    spreadRef,
    workpaperIdRef,
    dataReferenceDeleteQueue,
    processDataReferencesDeleteQueue,
    formulas,
  });

  const {
    updateSheetReferencesFromCells,
    updateReferencesFromCells,
    updateDataReferenceCellPositions,
    updateDataReferences,
  } = useCellTrackerUpdate({
    dataReferences,
    workpaperIdRef,
    dataReferencePositionUpdateQueue,
    processDataReferencePositionQueue,
    lastCellReviewActionTimestamp,
    updateCellReferencesTags,
    enqueueCellTagUpdateQueue,
    sendMessage,
  });

  const { trackPastedReference, trackCellReviewPastedReferences } = useCellTrackerPaste({
    dataReferences,
    enqueueManyDataReferences,
    dataReferenceDeleteQueue,
    processCellReviewQueue,
    processDataReferencesDeleteQueue,
    updateCellReferencesTags,
    updateDataReferences,
    updateDataReferenceCellPositions,
    deleteCellReviewCommand,
    workpaperIdRef,
    addToHistory,
    spreadRef,
  });

  /**
   * =====================
   *      Hook output
   * =====================
   */
  return {
    trackPosition,
    trackPastedReference,
    trackMetadataUpdate,
    cellValueChangedHandler,
    dataReferences,
    dataReferencePositionUpdateQueue,
    dataReferenceDeleteQueue,
    dataReferenceCellTagUpdateQueue,
  };

  /**
   * =====================
   *      trackPosition
   * =====================
   */
  function trackPosition(command, sheet) {
    if (dataReferences.current) {
      deleteDataReferences(command, deleteCellReviewCommand);
      trackSheetNameUpdate(command);

      const sheetReferences = dataReferences.current.filter(r => {
        if (r.type === DATA_LINK && r.workpaperId !== workpaperIdRef.current) {
          const { workpaperId, sheetName } = typeof r.parameters === 'string' ? JSON.parse(r.parameters) : r.parameters;
          return workpaperId === workpaperIdRef.current && sheetName === command?.sheetName;
        }
        return r.sheetName === command?.sheetName && r.workpaperId === workpaperIdRef.current;
      });

      if (sheetReferences && sheetReferences.length > 0) {
        let referencesToUpdate = sheetReferences;
        const isUndo = command.actionType === 1;
        const { selections, isRow } = command;

        if (
          selections &&
          selections.length > 0 &&
          command.cmd !== CommandType.DragDrop &&
          command.cmd !== CommandType.DragFill &&
          command.cmd !== CommandType.ClearCellValue
        ) {
          // Handles row/column insert/delete operations
          referencesToUpdate = handleRowAndColumnInsertDelete(
            command,
            referencesToUpdate,
            isRow,
            isUndo,
            updateCellReferencesTags
          );
        } else if (command.cmd === CommandType.DragDrop) {
          // Handles drag-drop logic
          referencesToUpdate = handleDragDrop(command, sheet, sheetReferences);
        } else if (command.cmd === CommandType.DragFill) {
          // Handles drag-fill logic
          referencesToUpdate = handleDragFill(command, sheet, sheetReferences);
        } else if (command.cmd === CommandType.ClearCellValue) {
          // Handles clearing cell values
          referencesToUpdate = handleClearCellValue(command, sheet, sheetReferences);
        } else {
          // default path if none of the above
          referencesToUpdate = [];
        }

        if (Object.values(CommandType).indexOf(command.cmd) > -1 && command.cmd !== CommandType.CellPasting) {
          updateDataReferences(referencesToUpdate);
        }
      }
    }

    if (command.cmd !== 'Designer.setTag' && !process.env.REACT_APP_NODE_IGNORE_DB_UPDATE) {
      updateSheetReferencesFromCells(sheet, command.sheetNames);
      handleDataLinkCellDependencyChange(sheet);
    }
  }

  /**
   * =====================
   *      Handlers
   * =====================
   */

  function handleRowAndColumnInsertDelete(command, referencesToUpdate, isRow, isUndo) {
    const { cmd, selections } = command;

    for (let i = 0; i < selections.length; i++) {
      let { row, col, rowCount, colCount } = selections[i];
      row = row < 0 ? 0 : row;
      col = col < 0 ? 0 : col;
      switch (cmd) {
        case CommandType.InsertEntireRow:
        case CommandType.InsertPartialRow:
        case CommandType.InsertCellsDown:
        case CommandType.DeleteEntireRow:
        case CommandType.DeleteCellsUp:
        case CommandType.InsertRowOrColumn && isRow:
        case CommandType.DeleteEntireRowColumn && isRow:
          referencesToUpdate = handleRowOperations(
            cmd,
            isUndo,
            rowCount,
            row,
            col,
            referencesToUpdate,
            updateDataReferenceCellPositions
          );
          updateCellReferencesTags = [...updateCellReferencesTags, ...referencesToUpdate];
          break;
        case CommandType.InsertEntireColumn:
        case CommandType.InsertCellsRight:
        case CommandType.DeleteEntireColumn:
        case CommandType.DeleteCellsLeft:
        case CommandType.InsertRowOrColumn && !isRow:
        case CommandType.DeleteEntireRowColumn && !isRow:
          referencesToUpdate = handleColumnOperations(
            cmd,
            isUndo,
            colCount,
            row,
            col,
            referencesToUpdate,
            updateDataReferenceCellPositions
          );
          updateCellReferencesTags = [...updateCellReferencesTags, ...referencesToUpdate];
          break;
        default:
          referencesToUpdate = [];
          break;
      }
    }

    return referencesToUpdate;
  }

  function handleDragDrop(command, sheet, sheetReferences) {
    const valueReferences = [];
    const tagReferences = [];

    const isUndo = command.actionType === 1;
    const isUndoMultiplier = isUndo ? -1 : 1;

    const rowColumnReferences = [];
    const rowCount = command.toRow - command.fromRow;
    const columnCount = command.toColumn - command.fromColumn;

    let selectedRanges = sheet.getSelections();

    for (let i = 0; i < selectedRanges.length; i++) {
      const selectedRange = selectedRanges[i];

      for (let r = 0; r < selectedRange.rowCount; r++) {
        for (let c = 0; c < selectedRange.colCount; c++) {
          const selectedRangeRow = (isUndo ? command.toRow : command.fromRow) + r;
          const selectedRangeCol = (isUndo ? command.toColumn : command.fromColumn) + c;
          const targetRangeRow = (isUndo ? command.fromRow : command.toRow) + r;
          const targetRangeCol = (isUndo ? command.fromColumn : command.toColumn) + c;

          const references = sheetReferences.filter(reference => {
            if (reference.type === DATA_LINK) {
              const params =
                typeof reference.parameters === 'string' ? JSON.parse(reference.parameters) : reference.parameters;

              return params.column === selectedRangeCol && params.row === selectedRangeRow;
            }

            return reference.column === selectedRangeCol && reference.row === selectedRangeRow;
          });

          trackCellReviewPastedReferences(
            valueReferences,
            tagReferences,
            command.toRow + r,
            command.toColumn + c,
            sheet.name(),
            sheet
          );

          if (references?.length) {
            if (!isUndo || !valueReferences.filter(x => references.filter(y => y.id === x.id)).length) {
              rowColumnReferences.push(...references);
            }

            tagReferences.push(
              ...references.map(rowColumnReference => {
                return {
                  row: targetRangeRow,
                  column: targetRangeCol,
                  value: rowColumnReference.value,
                  id: rowColumnReference.id,
                  sheetName: sheet.name(),
                };
              })
            );
          }
        }
      }
    }

    updateCellReferencesTags.push(...tagReferences);

    if (valueReferences.length) {
      updateDataReferences(valueReferences);
    }

    const newRowCount = rowCount * isUndoMultiplier;
    const newColCount = columnCount * isUndoMultiplier;

    return updateDataReferenceCellPositions(rowColumnReferences, 'rowcolumn', null, null, null, null, {
      rowCount: newRowCount,
      columnCount: newColCount,
    });
  }

  function handleDragFill(command, sheet, sheetReferences) {
    let referencesToUpdate = [];

    const valueReferences = [];
    const tagReferences = [];

    const selectedRanges = sheet.getSelections();

    const isUndo = command.actionType === 1;

    let { row, col, rowCount, colCount } = selectedRanges[0];

    for (let r = 0; r < (isUndo ? command.fillRange.rowCount : rowCount); r++) {
      for (let c = 0; c < (isUndo ? command.fillRange.colCount : colCount); c++) {
        const selectedRangeRow = (isUndo ? command.fillRange.row : row) + r;
        const selectedRangeCol = (isUndo ? command.fillRange.col : col) + c;

        const references = sheetReferences.filter(
          reference =>
            reference.column === selectedRangeCol &&
            reference.row === selectedRangeRow &&
            reference.type === CELL_REVIEW
        );

        if (references?.length) {
          references.forEach(reference => {
            const value = cleanDirtyCellValue(sheet.getCell(reference.row, reference.column).text());

            if (reference.value !== value && !valueReferences.find(x => x.id === reference.id)) {
              tagReferences.push({
                row: reference.row,
                column: reference.column,
                value: value,
                id: reference.id,
                sheetName: sheet.name(),
              });

              valueReferences.push({ ...reference, value: value });
            }
          });
        }
      }
    }

    if (valueReferences.length) {
      updateCellReferencesTags.push(...tagReferences);
      referencesToUpdate = [...referencesToUpdate, ...valueReferences];
    }

    return referencesToUpdate;
  }

  function handleClearCellValue(command, sheet, sheetReferences) {
    let referencesToUpdate = [];
    const valueReferences = [];
    const referenceIdsToDelete = [];

    for (let i = 0; i < command.ranges.length; i++) {
      const selectedRange = command.ranges[i];

      let { row, col, rowCount, colCount } = selectedRange;

      row = row < 0 ? 0 : row;
      col = col < 0 ? 0 : col;

      for (let r = 0; r < rowCount; r++) {
        for (let c = 0; c < colCount; c++) {
          const selectedRangeRow = row + r;
          const selectedRangeCol = col + c;

          const idsToDelete = updateReferencesFromCells(
            sheetReferences,
            sheet,
            selectedRangeRow,
            selectedRangeCol,
            valueReferences
          );

          if (idsToDelete?.length) {
            referenceIdsToDelete.push(...idsToDelete);
          }
        }
      }
    }

    referencesToUpdate = [...referencesToUpdate, ...valueReferences];

    if (referenceIdsToDelete.length > 0) {
      dataReferenceDeleteQueue.current = dataReferenceDeleteQueue.current.concat(referenceIdsToDelete);

      processDataReferencesDeleteQueue(
        workpaperIdRef.current,
        undefined,
        deleteCellReviewCommand?.current?.length,
        spreadRef?.current
      );

      enqueueCellTagUpdateQueue(updateCellReferencesTags, referencesToUpdate);
    }

    return referencesToUpdate;
  }

  /**
   * =====================
   *   Helper Functions
   * =====================
   */

  function trackMetadataUpdate(workpaperId, metadata) {
    const { newTaxPeriod, name } = metadata;

    const referencesToUpdate = dataReferences?.current?.reduce((toUpdate, r) => {
      if (r.type === DATA_LINK) {
        const params = typeof r.parameters === 'string' ? JSON.parse(r.parameters) : r.parameters;

        if (params.workpaperId === workpaperId) {
          if (params.taxPeriod !== newTaxPeriod || params.workpaperName !== name) {
            params.taxPeriod = newTaxPeriod;
            params.workpaperName = name;

            r.parameters = params;

            toUpdate.push(r);
          }
        }
      }
      return toUpdate;
    }, []);

    if (referencesToUpdate.length) {
      sendMessage(BroadcastChannels.SourceUpdateReferences, {
        type: EventTypes.DataLinkSourceUpdated,
        details: referencesToUpdate,
      });

      updateDataReferences(referencesToUpdate);
    }
  }

  function trackSheetNameUpdate(command) {
    if (command.cmd === CommandType.RenameSheet) {
      const { name: newName, oldName } = command;
      const referencesToUpdate = dataReferences.current.reduce((toUpdate, r) => {
        if (r.type === DATA_LINK) {
          const params = typeof r.parameters === 'string' ? JSON.parse(r.parameters) : r.parameters;

          if (params.workpaperId === workpaperIdRef.current) {
            if (params.sheetName === oldName) {
              params.sheetName = newName;
              r.parameters = params;
              toUpdate.push(r);
            }
          }
        }

        return toUpdate;
      }, []);

      if (referencesToUpdate.length) {
        sendMessage(BroadcastChannels.SourceUpdateReferences, {
          type: EventTypes.DataLinkSourceUpdated,
          details: referencesToUpdate,
        });
        updateDataReferences(referencesToUpdate);
      }
    }
  }

  function handleDataLinkCellDependencyChange(sheet) {
    const dataLinkReferences = dataReferences.current.filter(x => x.type === DATA_LINK);
    if (!dataLinkReferences.length) {
      return;
    }

    const ss = sheet?.getParent();

    ss.sheets.forEach(currSheet => {
      const dirtyCells = currSheet?.getDirtyCells();
      const affectedDataLinkReferencesFromDirtyCells = [];

      dirtyCells &&
        dirtyCells.forEach(dirtyCell => {
          dataLinkReferences.forEach(dataLinkRef => {
            const { row, column, sheetName } = JSON.parse(dataLinkRef?.parameters);

            if (sheetName === currSheet.name() && row === dirtyCell.row && column === dirtyCell.col) {
              // DataLink source cell value has changed from reference dependency
              if (dirtyCell.newValue) {
                affectedDataLinkReferencesFromDirtyCells.push({
                  dataReference: dataLinkRef,
                  newValue: dirtyCell.newValue,
                });

                console.log(
                  `updating affected data link reference from dirty cell with new value: "${dirtyCell.newValue}"`,
                  { dataLinkRef, dirtyCell }
                );
              }
            }
          });
        });

      if (affectedDataLinkReferencesFromDirtyCells?.length) {
        const updatedAffectedReferences = affectedDataLinkReferencesFromDirtyCells.map(
          ({ dataReference, newValue }) => {
            return {
              ...dataReference,
              value: newValue,
              newValue: newValue,
            };
          }
        );

        updateDataReferences(updatedAffectedReferences);
      }
    });
  }

  function cellValueChangedHandler(eventInfo) {
    if (eventInfo) {
      const sheet = eventInfo.sheet;

      const formula = sheet.getFormula(eventInfo.row, eventInfo.col);
      let hasCustomFormula = false;

      const valueReferences = [];

      if (formula) {
        hasCustomFormula = formulas.some(frml => formula.includes(frml));
      }

      if (!eventInfo.newValue || !hasCustomFormula) {
        const sheetReferences = dataReferences?.current?.filter(r => {
          if (r.type === DATA_LINK && r.workpaperId !== workpaperIdRef.current) {
            const { workpaperId, sheetName } =
              typeof r.parameters === 'string' ? JSON.parse(r.parameters) : r.parameters;

            return workpaperId === workpaperIdRef.current && sheetName === sheet.name();
          }

          return r.workpaperId === workpaperIdRef.current && r.sheetName === sheet.name();
        });

        const referenceIdsToDelete = updateReferencesFromCells(
          sheetReferences,
          sheet,
          eventInfo.row,
          eventInfo.col,
          valueReferences
        );

        if (valueReferences.length) {
          updateDataReferences(valueReferences);
        }

        if (referenceIdsToDelete?.length) {
          const referenceIds = dataReferenceDeleteQueue.current.concat(referenceIdsToDelete);

          dataReferences.current = dataReferences.current.filter(x => !referenceIds.some(y => y === x.id));

          dataReferenceDeleteQueue.current = referenceIds;

          processDataReferencesDeleteQueue(
            workpaperIdRef.current,
            undefined,
            deleteCellReviewCommand?.current?.length,
            spreadRef?.current
          );
        }

        enqueueCellTagUpdateQueue(updateCellReferencesTags);
      }

      if (eventInfo.newValue && eventInfo.newValue !== eventInfo.oldValue) {
        const affectedReferencesToUpdate = dataReferences.current.filter(
          x =>
            x.type === CELL_REVIEW &&
            x.row === eventInfo.row &&
            x.column === eventInfo.col &&
            x.sheetName === sheet.name()
        );

        if (affectedReferencesToUpdate?.length) {
          const updatedAffectedReferences = affectedReferencesToUpdate.map(reference => {
            return {
              ...reference,
              value: eventInfo.newValue,
              newValue: eventInfo.newValue,
            };
          });

          dataReferencePositionUpdateQueue.current = [
            ...dataReferencePositionUpdateQueue.current,
            ...updatedAffectedReferences,
          ];

          processDataReferencePositionQueue();
        }

        const dataLinkReferences = dataReferences.current.filter(x => x.type === DATA_LINK);

        const affectedDataLinkReferences = dataLinkReferences.filter(({ parameters }) => {
          const { row, column, sheetName } = JSON.parse(parameters);
          return row === eventInfo.row && column === eventInfo.col && sheetName === sheet.name();
        });

        if (affectedDataLinkReferences?.length) {
          const updatedAffectedReferences = affectedDataLinkReferences.map(reference => {
            return {
              ...reference,
              value: eventInfo.newValue,
              newValue: eventInfo.newValue,
            };
          });

          console.log(
            `updating affected data link references with new value: "${eventInfo.newValue}"`,
            updatedAffectedReferences
          );

          updateDataReferences(updatedAffectedReferences);
        }
      }
    }
  }
}
