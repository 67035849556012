import { eventNames, eventTrackTagNames, eventTypes } from './constants';

const { click, submit } = eventTypes;
const { interactiveClick, navigation, fileUploaded, feedbackForms, toolsContent, docExported } = eventNames;
const { interactive, navClicked, fileUpload, feedback, wkpTools, documentExported } = eventTrackTagNames;

const eventConfigurations = [
  // When adding a new event, make sure to update the switch condition in setPropValues util
  {
    eventType: click,
    eventName: docExported,
    tagPropertiesMapping: {
      [documentExported]: {
        itemClicked: 'item label',
        itemLocation: 'location on screen',
        itemType: 'Button input dropdown',
      },
    },
  },
  {
    eventType: click,
    eventName: interactiveClick,
    tagPropertiesMapping: {
      [interactive]: {
        itemClicked: 'item label',
        itemLocation: 'location on screen',
        itemType: 'Button input dropdown',
      },
    },
  },
  {
    eventType: click,
    eventName: navigation,
    tagPropertiesMapping: {
      [navClicked]: { navGroup: 'location on screen', navItem: 'up lef', navType: 'Button or anchor' },
    },
  },
  {
    eventType: click,
    eventName: toolsContent,
    tagPropertiesMapping: {
      [wkpTools]: { toolName: 'item label' },
    },
  },
  {
    eventType: submit,
    eventName: fileUploaded,
    tagPropertiesMapping: {
      [fileUpload]: { fileType: 'CSV or XLSX', uploadType: 'Browse, drag and drop' },
    },
  },
  {
    eventType: submit,
    eventName: feedbackForms,
    tagPropertiesMapping: {
      [feedback]: { feedbackFormTitle: 'item label' },
    },
  },
];

export default eventConfigurations;
