import { CommandType } from '../../../_shared/commandType';

/**
 * This hook handles row insert/delete logic.
 */
export function useCellTrackerRow() {
  function handleRowOperations(cmd, isUndo, rowCount, row, col, referencesToUpdate, updateDataReferenceCellPositions) {
    let rowPositionCount = rowCount;
    let rowFilter = 'row';
    let rowType = 'row';
    let rowOperation = 'greater-equal';

    if (
      cmd === CommandType.DeleteEntireRow ||
      cmd === CommandType.DeleteCellsUp ||
      cmd === CommandType.DeleteEntireRowColumn
    ) {
      rowPositionCount = -rowPositionCount;
    }

    if (cmd === CommandType.DeleteCellsUp || cmd === CommandType.InsertCellsDown) {
      rowFilter = 'column';
      rowOperation = 'equal';
    }

    if (isUndo) {
      rowPositionCount = -rowPositionCount;
      if (rowOperation === 'equal') {
        rowOperation = 'greater-equal';
      }
    }

    return updateDataReferenceCellPositions(
      referencesToUpdate,
      rowType,
      rowFilter,
      rowOperation,
      row,
      col,
      rowPositionCount
    );
  }

  return {
    handleRowOperations,
  };
}
