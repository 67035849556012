/* eslint-disable no-unused-vars */
import { IntTaxRateTooltipToggleHelper } from './intTaxRateTooltipHelper';
import { intTaxRateFormulaName } from '../_spreadsheets/formulas/intTaxRatesFormula';
import { sourceDataFormulaName } from '../_spreadsheets/formulas/sourceDataFormula';
import { stateApportionFormulaName } from '../_spreadsheets/formulas/stateApportionFormula';
import { stateTaxRateFormulaName } from '../_spreadsheets/formulas/stateTaxRateFormula';
import { getFunctionArgumentValues } from '../_spreadsheets/stateTaxRateFormulaHelper';
import { ApportionRateTooltipHelper } from './apportionmentTooltipHelper';
import { SourceDataTooltipHelper } from './sourceDataTooltipHelper';
import { TooltipToggleHelper } from './tooltipToggleHelper';
import { getSourceDataArguments } from '../_spreadsheets/sourceDataFormulaHelper';
import { StateTaxTooltipToggleHelper } from './stateTaxRateTooltipHelper';

/**
 * Factory function to create TooltipHelper instances based on formula type.
 * @param {any} sheet
 * @param {string} formula
 * @param {string} workpaperId
 * @param {any[]} datareferences
 * @param {{row: Number; col: Numeber; sheetName: string}} cell
 * @param {() => Promise<void>} onNotFound
 * @returns {TooltipToggleHelper | undefined}
 */
export function createTooltipToggleHelper(sheet, formula, workpaperId, datareferences, cell, onNotFound) {
  let fnArguments;
  switch (true) {
    case formula.includes(stateTaxRateFormulaName):
      fnArguments = getFunctionArgumentValues(sheet, formula, stateTaxRateFormulaName);

      return new StateTaxTooltipToggleHelper(fnArguments, workpaperId, datareferences, cell, onNotFound);

    case formula.includes(intTaxRateFormulaName):
      fnArguments = getFunctionArgumentValues(sheet, formula, intTaxRateFormulaName);
      return new IntTaxRateTooltipToggleHelper(fnArguments);

    case formula.includes(stateApportionFormulaName):
      fnArguments = getFunctionArgumentValues(sheet, formula, stateApportionFormulaName);
      return new ApportionRateTooltipHelper(fnArguments);

    case formula.includes(sourceDataFormulaName):
      // Use custom SDC argument getter
      fnArguments = getSourceDataArguments(sheet, formula).map(({ value }) => value);
      return new SourceDataTooltipHelper(fnArguments);

    default:
      return undefined;
  }
}
