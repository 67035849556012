import pendo from '../pendo-io';
import { useEffect } from 'react';
import { getUser } from '../components/_shared/auth';
import { getWKPProductCodes } from '../components/_shared/getProductCodes';
import { EnviromentName } from '../configs/params';

export function usePendo() {
  const { userId, companyId, companyName, companyNameOverride } = getUser() || {};
  const { productCode, editionCode } = getWKPProductCodes() || {};
  const psuFlag = companyNameOverride !== null;

  useEffect(() => {
    if (companyId) {
      if (EnviromentName && EnviromentName === 'LOCAL') {
        // do not initialize pendo on local
      } else {
        const params = {
          visitor: {
            id: userId,
            isPsuUser: psuFlag,
          },
          account: {
            id: companyId,
          },
          excludeAllText: false,
        };

        if (productCode) {
          params.account.companyName = companyName;
          params.account.companyNameOverride = psuFlag ? companyNameOverride : null;
          params.account.productCode = productCode;
          params.account.editionCode = editionCode;
        }

        pendo.initialize(params);
      }
    }
  }, [userId, psuFlag, companyId, companyName, companyNameOverride, productCode, editionCode]);
}
